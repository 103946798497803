import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import "./AuctionChat.css";
import moment from "moment";
import Broadcaster from "./Broadcaster";

window.Pusher = Pusher;

window.Echo = new Echo({
  broadcaster: "pusher",
  key: "123456",
  cluster: "mt1",
  wsHost: "ws-mt1.pusher.com",
  wsPort: 80,
  wssPort: 443,
  forceTLS: true,
  enabledTransports: ["ws", "wss"],
});

const Chat = (hashid) => {
  const [messages, setMessages] = useState([]);
  const [username, setUsername] = useState("");
  const [message, setMessage] = useState("");
  const [isChatVisible, setIsChatVisible] = useState(false);
  const chatContainerRef = useRef(null);
  const videoRef = useRef(null);
  const audioStreamRef = useRef(null);

  const currentUser = localStorage.getItem("username");
  //   const { hash_id } = useParams();

  const fetchMessages = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/chat/messages`)
      .then((response) => {
        setMessages(response.data);
      })
      .catch((error) => {
        console.error("Error fetching messages:", error);
      });
  };

  // console.log(hashid, "hashid")

  useEffect(() => {
    fetchMessages(hashid);
    // console.log(hashid, "lot id");
  }, [fetchMessages(hashid)]);

  useEffect(() => {
    const storedUsername = localStorage.getItem("username");
    if (storedUsername) {
      setUsername(storedUsername);
      setIsChatVisible(true);
      fetchMessages();
    }
  }, []);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    if (isChatVisible && videoRef.current) {
      navigator.mediaDevices
        .getUserMedia({
          video: { facingMode: { exact: "user" } },
          audio: true,
        })
        .then((stream) => {
          videoRef.current.srcObject = stream;
          audioStreamRef.current = stream;
        })
        .catch((error) => {
          console.error("Error accessing front camera and microphone:", error);
        });
    }
  }, [isChatVisible]);

  const handleStartChat = (event) => {
    event.preventDefault();
    if (!username) {
      alert("Please enter a username to start the chat.");
      return;
    }
    localStorage.setItem("username", username);
    setIsChatVisible(true);
    fetchMessages();
  };

  const handleSendMessage = (event) => {
    event.preventDefault();
    axios
      .post(`${process.env.REACT_APP_API_URL}/broadcast-message`, {
        username,
        message,
      })
      .then(() => {
        setMessage("");
        fetchMessages();
      })
      .catch((error) => {
        console.error("Error sending message:", error);
        if (error.response) {
          console.log("Error data:", error.response.data);
          console.log("Error status:", error.response.status);
        }
      });
  };

 
 

  return (
    <div className="chat-message">
      <Broadcaster />
      {/* {isWebcamVisible && (
        <div className="webcam-modal">
          <div className="webcam-modal-content">
            <video ref={videoRef} autoPlay className="webcam-feed" controls />
          </div>
        </div>
      )} */}
      {!isChatVisible ? (
        <div id="start-chat">
          <form onSubmit={handleStartChat}>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Enter your name"
              required
            />
            <input type="submit" value="Let's chat" />
          </form>
        </div>
      ) : (
        <div id="chat-part" className="border rounded-xl">
          <h2 className="text-center py-2">Bidding Chats</h2>
          <div id="chat-container" className="relative" ref={chatContainerRef}>
            {messages.map((msg, index) => (
              <div
                className={`message ${
                  msg.username === currentUser ? "user" : "other"
                } border rounded px-2 py-2 w-50`}
                key={index}
              >
                <div className="username">
                  <b className="capitalize">{msg.username}</b>&nbsp;
                  <small className="timestamp">
                    {moment(msg.created_at).format("D MMM YYYY, HH:mm:ss A")}
                  </small>
                </div>
                <div className="message-content">
                  <span>{msg.message}</span>
                </div>
              </div>
            ))}
          </div>        
        </div>
      )}
    </div>
  );
};

export default Chat;
