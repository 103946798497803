import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import "./Map.css";

const mapContainerStyle = {
  width: "100%",
  height: "450px",
  margin: "auto",
};

const center = {
  lat: 38.7749,
  lng: -100.4194,
};

const MapEvents = () => {
  const [auctionData, setAuctionData] = useState([]);
  const [selectedAuction, setSelectedAuction] = useState(null);
  const [searchParams, setSearchParams] = useState({
    zip_code: "",
    name: "",
    miles: "",
  });

  // Load the Google Maps script
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDHO0aH6WMfXJNCDYkWS-ExC6_Ei5CgBE8",
  });

  // Fetch auctions based on search parameters
  const fetchAuctions = async () => {
    try {
      const queryParams = new URLSearchParams({
        zip_code: searchParams.zip_code,
        name: searchParams.name,
        miles: searchParams.miles,
        map: "map",
      }).toString();

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/search?${queryParams}`
      );
      const data = await response.json();
      setAuctionData(data.data.data);
    } catch (error) {
      console.error("Error fetching auction data:", error);
    }
  };

  useEffect(() => {
    fetchAuctions();
  }, []); // Initial load without filters

  // Handle form submission
  const handleSearch = (e) => {
    e.preventDefault();
    fetchAuctions();
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading maps...</div>;

  return (
    <>
      <section>
        <div className="container">
          <form
            className="d-flex justify-content-center align-items-center mb-4"
            onSubmit={handleSearch}>
            <select className="form-control me-2 w-25"
              value={searchParams.miles}
              onChange={(e) =>
                setSearchParams((prev) => ({ ...prev, miles: e.target.value }))
              }>
              <option value="">Anywhere</option>
              <option value="25">25 Miles</option>
              <option value="50">50 Miles</option>
              <option value="100">100 Miles</option>
              <option value="250">250 Miles</option>
              <option value="500">500 Miles</option>
            </select>
            <input
              type="text"
              className="form-control me-2 w-25"
              placeholder="Enter Zip Code"
              value={searchParams.zip_code}
              onChange={(e) =>
                setSearchParams((prev) => ({ ...prev, zip_code: e.target.value }))
              }
            />
            <input
              type="text"
              className="form-control me-2 w-25"
              placeholder="search by auction"
              value={searchParams.name}
              onChange={(e) =>
                setSearchParams((prev) => ({ ...prev, name: e.target.value }))
              }
            />
            <button type="submit" className="btn btn-primary">
              Search
            </button>
          </form>
          <GoogleMap mapContainerStyle={mapContainerStyle} zoom={4} center={center}>
            {/* Render markers for auctions */}
            {auctionData &&
              auctionData.map((auction) => {
                const lat = parseFloat(auction.latitude);
                const lng = parseFloat(auction.longitude);
                return !isNaN(lat) && !isNaN(lng) ? (
                  <Marker
                    key={auction.id}
                    position={{ lat, lng }}
                    onClick={() => setSelectedAuction(auction)}
                  />
                ) : null;
              })}
            {selectedAuction && (
              <InfoWindow
                position={{
                  lat: parseFloat(selectedAuction.latitude),
                  lng: parseFloat(selectedAuction.longitude),
                }}
                onCloseClick={() => setSelectedAuction(null)}>
                <div style={{ maxWidth: "300px" }} className="text-center">
                  <Link
                    to={`/auctions/${selectedAuction.hash_id}`}
                    className="text-decoration-none">
                    <h5 className="text-dark text-capitalize">
                      {selectedAuction?.name}
                    </h5>
                  </Link>
                  <p>
                    {selectedAuction?.city?.name}, {selectedAuction?.state?.name},{" "}
                    {selectedAuction?.country?.name}, {selectedAuction?.zip_code}
                  </p>
                  <Link
                    to={`/auctions/${selectedAuction.hash_id}`}
                    className="text-decoration-none">
                    <p className="font-semibold" style={{ fontSize: "14px" }}>
                      Click here to view details
                    </p>
                  </Link>
                </div>
              </InfoWindow>
            )}
          </GoogleMap>
        </div>
      </section>
    </>
  );
};

export default MapEvents;
