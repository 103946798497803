import React, { useEffect, useState } from "react";
import "./Blog.css";
import axios from "axios";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function Blog() {
  const [blog, setBlog] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [blogsPerPage] = useState(12);
  const url = window.location.href;

  const fetchBlog = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_API_URL}/blog`)
      .then((response) => {
        setBlog(response.data.data.data);
      })
    } catch (error) {
      console.error("Error fetching blog:", error);
    }
  };

  useEffect(() => {
    fetchBlog();
  }, []);

  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blog.slice(indexOfFirstBlog, indexOfLastBlog);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <Helmet>
        <title>Blogs - Auctionslive</title>
        <meta
          name="description"
          content="Learn how to get started with live online bidding at AuctionsLive.net. Discover tips, auction types, and how to bid on collectibles, real estate, and more. Dive into the world of online auctions today!"
        />
        <meta property="og:title" content="Blogs - Auctionslive" />
        <meta
          property="og:description"
          content="Learn how to get started with live online bidding at AuctionsLive.net. Discover tips, auction types, and how to bid on collectibles, real estate, and more. Dive into the world of online auctions today!"
        />
        <meta property="og:url" content={url} />
        <link rel="canonical" href={url} />
      </Helmet>
      <section className="blog mt-5 mb-4">
        <div className="container">
          <div className="row">
            <h1 className="blog-title">
              Auctionslive Blog – Get the Latest Updates and Auction Event News
            </h1>
          </div>
          {currentBlogs &&
            currentBlogs.map((item) => {
              const words = item.description ? item.description.split(" ") : [];
              const truncatedDescription =
                words.length > 30
                  ? words.slice(0, 30).join(" ") + "..."
                  : item.description;

              const formattedDate = format(
                new Date(item.created_at),
                "MMMM dd, yyyy, h:mm a"
              );

              return (
                <div className="p-4 mt-4 card" key={item.id}>
                  <div className="row">
                    <div className="blog-img col-12 col-md-4">
                      <Link
                        to={`/blog/${item.slug}`}
                        className="readmore-btn text-decoration-none"
                      >
                        <img
                          src={`${process.env.REACT_APP_BASE_IMAGE_URL}/media/${item.image}`}
                          alt="blog"
                          className="img"
                          loading="lazy"
                        />
                      </Link>
                    </div>
                    <div className="blog-body  col-12 col-md-8 text-start">
                      <Link
                        to={`/blog/${item.slug}`}
                        className="readmore-btn text-decoration-none"
                      >
                        <h5 className="card-title">{item?.name}</h5>
                      </Link>
                      <span>{formattedDate}</span>
                      <p
                        className="card-text mt-4"
                        dangerouslySetInnerHTML={{
                          __html: truncatedDescription,
                        }}
                      ></p>

                      <Link to={`/blog/${item.slug}`} className="readmore-btn">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          {/* Pagination controls */}
          <Pagination
            blogsPerPage={blogsPerPage}
            totalBlogs={blog.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        </div>
      </section>
    </>
  );
}

// Pagination component
const Pagination = ({ blogsPerPage, totalBlogs, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalBlogs / blogsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className="pagination">
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={`page-item ${number === currentPage ? "active" : ""}`}
          >
            <button onClick={() => paginate(number)} className="page-link">
              {number}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Blog;
