import React, { useCallback, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import { format } from "date-fns";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { CircularProgress } from "@mui/material";

function Sellerdetails() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: 10,
  });

  const columns = [
    {
      name: "sr_no",
      label: "Sr.No",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return dataIndex + 1;
        },
        sort: false,
      },
    },
    { name: "email", label: "Email" },
    { name: "created_at", label: "Subscribed At", options: { sort: true } },
    {
      name: "Action",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <VisibilityIcon
              onClick={() => handleViewClick(tableMeta.rowIndex)}
              style={{ cursor: "pointer", color: "green" }}
            />
          );
        },
      },
    },
  ];

  const fetchData = useCallback(async (page = 1, perPage = 10) => {
    const accessToken = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/sellstuff?page=${page}&per_page=${perPage}`,
        { headers }
      );
      // console.log(response.data.data, "response");
      const { data, current_page, last_page, per_page } = response.data.data;
      const formattedData = data.map((item) => ({
        ...item,
        created_at: format(new Date(item.created_at), "MMMM dd, yyyy, h:mm a"),
      }));
      setData(formattedData || []);
      // console.log("blog list", response.data.data)
      setLoading(false);
      setPagination({
        currentPage: current_page,
        lastPage: last_page,
        perPage: per_page,
      });
    } catch (error) {
      console.error("Error fetching auctions:", error);
    }
  });

  useEffect(() => {
    fetchData();
  }, []);

  const handleViewClick = (rowIndex) => {
    const rowData = data[rowIndex];
    setSelectedRow(rowData);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedRow(null);
  };

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    download: false,
    print: false,
    pagination: false,
    textLabels: {
      body: {
        noMatch: "No SellStuff Found",
      },
    },
  };

  const handlePreviousPage = () => {
    if (pagination.currentPage > 1) {
      fetchData(pagination.currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (pagination.currentPage < pagination.lastPage) {
      fetchData(pagination.currentPage + 1);
    }
  };

  return (
    <section className="admin-subscribe">
      <div className="container">
        <div className="row mt-5 px-3">
          {loading ? (
            <div className="d-flex justify-content-center">
              <CircularProgress />
            </div>
          ) : (
            <>
              <MUIDataTable
                title={"SellStuff"}
                data={data || []}
                columns={columns}
                options={options}
              />
              <div className="d-flex justify-content-end align-items-center pagination">
                {pagination && (
                  <div className="d-flex align-items-center pagi">
                    <button
                      onClick={handlePreviousPage}
                      disabled={pagination.currentPage === 1}
                      cursor="pointer"
                    >
                      <ArrowBackIosIcon />
                    </button>
                    <span className="font-semibold ">
                      {pagination.currentPage} of {pagination.lastPage}
                    </span>
                    <button
                      onClick={handleNextPage}
                      disabled={pagination.currentPage === pagination.lastPage}
                      cursor="pointer"
                    >
                      <ArrowForwardIosIcon />
                    </button>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {/* Modal */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedRow?.name} Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedRow ? (
            <div className="px-3">
              <div className="d-flex justify-content-between align-items-center text-dark">
                <p>
                  <strong>Name:</strong> {selectedRow?.name}
                </p>
                <p>
                  <strong>Email:</strong> {selectedRow?.email}
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center text-dark">
                <p>
                  <strong>Phone:</strong> {selectedRow?.phone}
                </p>
                <p>
                  <strong>Zipcode:</strong> {selectedRow?.zipcode}
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center text-dark">
                <p>
                  <strong>Auctioneer:</strong> {selectedRow?.auctioneer}
                </p>
              </div>
              <p className="text-dark">
                <strong>Description:</strong> {selectedRow?.description}
              </p>
            </div>
          ) : (
            <p>No details available</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
}

export default Sellerdetails;
