import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Signinmodel from "../pages/Siginmodel/Signinmodel";

const Header3 = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const handleClosed = () => setOpen(false);
  const navigator = useNavigate();


  const isActive = (path) => {
    return location.pathname === path ? "active-link" : "";
  };
  const token = localStorage.getItem("access_token");

  const handleOpenLoginModal = () => {
    setOpen(true);
  };

  const handleClickd = () => {
    const isLoggedIn = !!localStorage.getItem("access_token");
    if (!isLoggedIn) {
      handleOpenLoginModal();
    } else {
      navigator(`/account/watchlist`);
    }
  };

  return (
    <>
      <div
        className="header-last flex items-center justify-center"
        style={{ background: "#2695FF" }}
      >
        <div className="container flex items-center justify-center">
          <div className="row last-header">
            <div className="col-md-12 d-flex flex-md-column flex-md-row ">
              <div className="header-item-list d-flex text-uppercase ml-md-3 categories-list  p-3">
                <p
                  className={`header-categories flex items-center justify-center m-0 ${isActive(
                    "/lots"
                  )}`}>
                  <Link
                    to="/lots"
                    className="no-underline font-semibold text-white  hover:no-underline focus:shadow-none active:font-bold visited:font:bold"
                  >
                    All categories
                  </Link>
                </p>
                <p
                  className={`header-categories flex items-center justify-center m-0 ${isActive(
                    "/lots?status=CLOSING"
                  )}`}>
                  <Link
                    to="/lots?status=CLOSING"
                    className="no-underline font-semibold text-white focus:shadow-none hover:no-underline active:font-bold visited:font:bold"
                  >
                    Today’s events
                  </Link>
                </p>
                <p
                  className={`header-categories flex items-center justify-center m-0 ${isActive(
                    "/stateauctions"
                  )}`}>
                  <Link
                    to="/stateauctions"
                    className="no-underline font-semibold text-white focus:shadow-none hover:no-underline active:font-bold visited:font:bold"
                  >
                    Auctions by state
                  </Link>
                </p>
                <p
                  className={`header-categories flex items-center justify-center m-0 ${isActive(
                    "/companysearch"
                  )}`}>
                  <Link
                    to="/companysearch"
                    className="no-underline font-semibold text-white focus:shadow-none hover:no-underline active:font-bold visited:font:bold"
                  >
                    Company search
                  </Link>
                </p>
                {/* {token ? ( */}
                <p
                  className={`header-categories flex items-center justify-center m-0 ${isActive(
                    "/account/pastwatchlist"
                  )}`} style={{ cursor: "pointer" }}>
                  {/* <Link
                    to="/account/watchlist" */}
                  <a
                    className="no-underline font-semibold text-white focus:shadow-none hover:no-underline active:font-bold visited:font:bold"
                    onClick={handleClickd}>
                    Watch list / Bids
                  </a>
                  {/* </Link> */}
                </p>
                {/* ) : (
                <p className="d-none"></p>
              )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Signinmodel open={open} handleClose={handleClosed} />
    </>
  );
};

export default Header3;
