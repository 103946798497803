import { React, useCallback, useEffect, useState } from "react";
import axios from "axios";
import MUIDataTable from "mui-datatables";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import EditCountry from "./EditCountry";
import { CircularProgress } from "@mui/material";

function CountryTabel() {
  const [country, setCountry] = useState([]);
  const [id, setId] = useState("");
  const [openedit, setOpenedit] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: 10,
  });
  const [loading, setLoading] = useState(true);

  const fetchCountry = useCallback(async (page = 1, perPage = 10) => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      console.error("Access token not found in localStorage.");
      setLoading(false);
      return;
    }
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/countries/list?page=${page}&per_page=${perPage}`,
        { headers }
      );
      //   console.log(response.data, "response");
      const { data, current_page, last_page, per_page } = response.data.data;
      setCountry(data);
      // console.log(data, "data");
      setPagination({
        currentPage: current_page,
        lastPage: last_page,
        perPage: per_page,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching auctions:", error);
    }
  });

  useEffect(() => {
    fetchCountry();
  }, []);

  const columns = [
    {
      name: "number",
      label: "SrNo.",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (_value, tableMeta) => {
          return (
            pagination.perPage * (pagination.currentPage - 1) +
            tableMeta.rowIndex +
            1
          );
        },
      },
    },
    {
      name: "name",
      label: "Country",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "is_active",
      label: "Status",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const statusText = value === 0 ? "Inactive" : "Active";
          const statusColor = value === 0 ? "#6C757D" : "#2695FF";

          const rowData = tableMeta.rowData;

          return (
            <span
              style={{
                backgroundColor: statusColor,
                fontWeight: "500",
                fontSize: "14px",
                cursor: "pointer",
              }}
              className="badge rounded-pill p-2 ps-3 px-3"
              onClick={() => openEditModal(rowData)}
            >
              {statusText}
            </span>
          );
        },
      },
    },
    {
      name: "hash_id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <>
              <div className="d-flex flex-wrap align-items-center gap-2">
                <Link
                  to={`/super/admin/state/${value}`}
                  style={{ textDecoration: "none" }}
                >
                  <p
                    style={{
                      color: "#2695FF",
                      fontSize: "14px",
                      cursor: "pointer",
                      fontWeight: "500",
                      marginBottom: "0",
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    }}
                    className="badge rounded-pill p-2 ps-3 px-3"
                  >
                    State
                  </p>
                </Link>
              </div>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    viewColumns: false,
    selectableRows: "none",
    responsive: "standard",
    download: false,
    print: false,
    pagination: false,
    textLabels: {
      body: {
        noMatch: "No Country Found",
      },
    },
  };

  const openEditModal = (value) => {
    setId(value);
    // console.log(value)
    setOpenedit(true);
  };

  const handlePreviousPage = () => {
    if (pagination.currentPage > 1) {
      fetchCountry(pagination.currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (pagination.currentPage < pagination.lastPage) {
      fetchCountry(pagination.currentPage + 1);
    }
  };

  return (
    <>
      <section className="mt-5">
        <div className="container">
          {loading ? (
            <div className="d-flex justify-content-center">
              <CircularProgress />
            </div>
          ) : (
            <>
              <div className="row p-3">
                <MUIDataTable
                  title={"Country"}
                  data={country}
                  columns={columns}
                  options={options}
                />
              </div>
              <div className="d-flex justify-content-end align-items-center pagination">
                {pagination && (
                  <div className="d-flex align-items-center pagi">
                    <button
                      onClick={handlePreviousPage}
                      disabled={pagination.currentPage === 1}
                      cursor="pointer"
                    >
                      <ArrowBackIosIcon />
                    </button>
                    <span className="font-semibold ">
                      {pagination.currentPage} of {pagination.lastPage}
                    </span>
                    <button
                      onClick={handleNextPage}
                      disabled={pagination.currentPage === pagination.lastPage}
                      cursor="pointer"
                    >
                      <ArrowForwardIosIcon />
                    </button>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </section>
      <EditCountry
        open={openedit}
        handleClose={() => setOpenedit(false)}
        value={id}
        fetchCountry={fetchCountry}
      />
    </>
  );
}

export default CountryTabel;
