// import React, { useState, useEffect } from "react";
import React from "react";
// import axios from "axios";
// import { Table, Alert, Pagination } from "react-bootstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Configuration.css";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Mails from "../Bulkmail/Mails";
// import EditModal from "./EditModal";
// import ViewModal from "./ViewModal";
// import ConfirmModal from "./ConfirmModal";

// const ITEMS_PER_PAGE = 20;

const Configuration = () => {
  // const [configurations, setConfigurations] = useState([]);
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);
  // const [showEditModal, setShowEditModal] = useState(false);
  // const [showViewModal, setShowViewModal] = useState(false);
  // const [showConfirmModal, setShowConfirmModal] = useState(false);
  // const [selectedConfig, setSelectedConfig] = useState(null);
  // const [newValue, setNewValue] = useState("");
  // const [currentPage, setCurrentPage] = useState(1);

  //  const fetchConfigurations = () => {
  //   setLoading(true);
  //   setError(null);
  //   const accessToken = localStorage.getItem("access_token");
  //   if (!accessToken) {
  //     setError("Access token not found.");
  //     setLoading(false);
  //     return;
  //   }
  //   axios.get(
  //     `${process.env.REACT_APP_API_URL}/admin/configurations`,
  //     {
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //         "ngrok-skip-browser-warning": "69420",
  //       },
  //     }
  //   )
  //   .then((response) => {
  //     setConfigurations(response.data.data);
  //     setLoading(false);
  //   })
  //   .catch((error) => {
  //     console.error("Error fetching configurations:", error);
  //     setError("An error occurred while fetching configurations.");
  //     setLoading(false);
  //   })
  // }

  // useEffect(() => {
  //   fetchConfigurations();
  // }, []);

  // const handleEdit = (config) => {
  //   setSelectedConfig(config);
  //   setNewValue(config.value);
  //   setShowEditModal(true);
  // };

  // const handleView = (config) => {
  //   setSelectedConfig(config);
  //   setShowViewModal(true);
  // };

  // const handleSaveChanges = async () => {
  //   const accessToken = localStorage.getItem("access_token");
  //   if (!accessToken) {
  //     setError("Access token not found.");
  //     return;
  //   }

  //   try {
  //     await axios.post(
  //       `${process.env.REACT_APP_API_URL}/admin/configurations/update/${selectedConfig.hash_id}`,
  //       { value: newValue },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`,
  //           "ngrok-skip-browser-warning": "69420",
  //         },
  //       }
  //     );
  //     toast.success("Configuration updated successfully!");
  //     fetchConfigurations(); // Refresh the list
  //   } catch (error) {
  //     console.error("Error updating configuration:", error);
  //     toast.error("An error occurred while updating the configuration.");
  //   } finally {
  //     setShowEditModal(false);
  //     setShowConfirmModal(false);
  //   }
  // };

  // const totalPages = Math.ceil(configurations.length / ITEMS_PER_PAGE);
  // const currentItems = configurations.slice(
  //   (currentPage - 1) * ITEMS_PER_PAGE,
  //   currentPage * ITEMS_PER_PAGE
  // );

  // if (loading)
  //   return (
  //     <div className="loader-overlay">
  //       <div className="spinner-border-custom"></div>
  //     </div>
  //   );

  // if (error)
  //   return (
  //     <div className="text-center mt-5">
  //       <Alert variant="danger">{error}</Alert>
  //     </div>
  //   );

  return (
    // <div className={`container pt-3 `}>
    //   <h2>Configurations</h2>
    //   {configurations.length === 0 ? (
    //     <div className="text-center mt-5">
    //       <Alert variant="info">No data found</Alert>
    //     </div>
    //   ) : (
    //     <>
    //       <Table
    //         striped
    //         bordered
    //         hover
    //         className="configuration-table border mt-5 mb-5"
    //       >
    //         <thead>
    //           <tr>
    //             <th>Key</th>
    //             <th>Value</th>
    //             <th>Actions</th>
    //           </tr>
    //         </thead>
    //         <tbody>
    //           {currentItems.map((config) => (
    //             <tr key={config.hash_id}>
    //               <td>{config?.key}</td>
    //               <td>{config?.value}</td>
    //               <td>
    //                 <button
    //                   className="btn btn-link btn-sm"
    //                   onClick={() => handleView(config)}
    //                 >
    //                   <FontAwesomeIcon icon={faEye} color="green" />
    //                 </button>
    //                 <button
    //                   className="btn btn-link btn-sm"
    //                   onClick={() => handleEdit(config)}
    //                 >
    //                   <FontAwesomeIcon icon={faEdit} color="blue" />
    //                 </button>
    //               </td>
    //             </tr>
    //           ))}
    //         </tbody>
    //       </Table>

    //       <Pagination className="justify-content-center">
    //         <Pagination.Prev
    //           disabled={currentPage === 1}
    //           onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
    //         />
    //         {Array.from({ length: totalPages }, (_, index) => index + 1).map(
    //           (page) => (
    //             <Pagination.Item
    //               key={page}
    //               active={page === currentPage}
    //               onClick={() => setCurrentPage(page)}
    //             >
    //               {page}
    //             </Pagination.Item>
    //           )
    //         )}
    //         <Pagination.Next
    //           disabled={currentPage === totalPages}
    //           onClick={() =>
    //             setCurrentPage((prev) => Math.min(prev + 1, totalPages))
    //           }
    //         />
    //       </Pagination>
    //     </>
    //   )}
    //   <ViewModal
    //     show={showViewModal}
    //     onHide={() => setShowViewModal(false)}
    //     config={selectedConfig}
    //   />
    //   <EditModal
    //     show={showEditModal}
    //     onHide={() => setShowEditModal(false)}
    //     config={selectedConfig}
    //     newValue={newValue}
    //     onNewValueChange={setNewValue}
    //     onSave={() => setShowConfirmModal(true)}
    //   />
    //   <ConfirmModal
    //     show={showConfirmModal}
    //     onHide={() => setShowConfirmModal(false)}
    //     onConfirm={handleSaveChanges}
    //   />
    //   <ToastContainer />
    // </div>
    <>
      <Mails/>
    </>
  );
};

export default Configuration;
