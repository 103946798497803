import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import React from "react";
// import PaypalButton from "./PaypalButton";

function Paypal() {
  return (
    <>
      <PayPalScriptProvider options={{ "client-id": "YOUR_CLIENT_ID" }}>
        <PayPalButtons
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: { value: 10 },
                  // payee: { email_address: auctioneerPayPalEmail }, 
                },
              ],
            });
          }}
          onApprove={(data, actions) => {
            return actions.order.capture().then((details) => {
              console.log("Payment successful:", details);
              // Handle order success (update database, send notifications)
            });
          }}
        />
      </PayPalScriptProvider>
    </>
  );
}

export default Paypal;
