import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import "./Newlot.css";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";


function NewLot({ handleClose, open, fetchLots, id }) {

  const [isSubmitting, setIsSubmitting] = useState("");
  const [categories, setCategories] = useState([]);
  const [images, setImages] = useState([]);

  /*------------get category-------------*/
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/categories`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        const { data } = response.data;
        setCategories(data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  //--------- handle lot image
  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    setImages((prevImages) => [...prevImages, ...files]);
    formik.setFieldValue("images", [...images, ...files]);
  };




  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
    estimate_from: Yup.string().required("Estimate from is required"),
    estimate_to: Yup.string().required("Estimate to is required"),
    category_id: Yup.string().required("Category is required"),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      estimate_from: "",
      estimate_to: "",
      lot_meta_title: "",
      lot_meta_description: "",
      category_id: "",
      images: [],
      auction_id: id,
      reserve_price: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // console.log(values, "form values");
      setIsSubmitting(true);
      const formData = new FormData();
      formData.append("title", values.title);
      formData.append("description", values.description);
      formData.append("estimate_from", values.estimate_from);
      formData.append("estimate_to", values.estimate_to);
      formData.append("reserve_price", values.reserve_price);
      formData.append("category_id", values.category_id);
      formData.append("auction_id", values.auction_id);
      formData.append("lot_meta_title", values.lot_meta_title);
      formData.append("lot_meta_description", values.lot_meta_description);
      values.images.forEach((image, index) => {
        formData.append(`images[${index}]`, image);
      });
      try {
        const accessToken = localStorage.getItem("access_token");
        if (!accessToken) {
          console.error("Access token not found in localStorage.");
          return;
        }
        const apiHeaders = {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        };
        axios.post(
          `${process.env.REACT_APP_API_URL}/create/auctions/lot`,
          formData,
          { headers: apiHeaders }
        )
          .then((response) => {
            // toast.success("Lot created successfully!");
            Swal.fire("Lot created successfully!")
            setIsSubmitting(false);
            handleClose();
            formik.resetForm();
            setImages([]);
            fetchLots();
          })
      } catch (error) {
        console.error("Error lot auction:", error);
        setIsSubmitting(false);
        toast.error("Error lot auction:", error);
      }
    },
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            className="d-grid p-4 lotdetail-section"
            style={{ overflowY: "scroll", height: "500px" }}
          >
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <h2 style={{ color: "#2695FF" }}>New Lot</h2>
              <button
                type="button"
                class="close text-end"
                onClick={handleClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="d-flex align-items-center mb-3">
                <label>Lot Title: </label>
                <div className="w-100">
                  <input
                    type="text"
                    placeholder="Lot Title"
                    name="title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.title && formik.errors.title && (
                    <p className="err mb-0 ">{formik.errors.title}</p>
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center mb-3">
                <label>Lot Description: </label>
                <div className="w-100">
                  <textarea
                    placeholder="Lot description"
                    rows={3}
                    name="description"
                    id="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  ></textarea>
                  {formik.touched.description && formik.errors.description && (
                    <p className="err mb-0 ">{formik.errors.description}</p>
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center mb-3">
                <label>Estimate From: </label>
                <div className="w-100">
                  <input
                    type="text"
                    placeholder="Estimate from"
                    name="estimate_from"
                    value={formik.values.estimate_from}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.estimate_from &&
                    formik.errors.estimate_from && (
                      <p className="err mb-0 ">{formik.errors.estimate_from}</p>
                    )}
                </div>
              </div>
              <div className="d-flex align-items-center mb-3">
                <label>Estimate To: </label>
                <div className="w-100">
                  <input
                    type="text"
                    placeholder="Estimate to"
                    name="estimate_to"
                    value={formik.values.estimate_to}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.estimate_to && formik.errors.estimate_to && (
                    <p className="err mb-0 ">{formik.errors.estimate_to}</p>
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center mb-3">
                <label>Reserve Price: </label>
                <div className="w-100">
                  <input
                    type="text"
                    placeholder="Reserve price"
                    name="reserve_price"
                    value={formik.values.reserve_price}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.reserve_price &&
                    formik.errors.reserve_price && (
                      <p className="err mb-0 ">{formik.errors.reserve_price}</p>
                    )}
                </div>
              </div>
              <div className="d-flex align-items-center mb-3">
                <label>Category: </label>
                <div className="w-100">
                  <select
                    name="category_id"
                    id="category_id"
                    value={formik.values.category_id}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option disabled value="">-Select Category-</option>
                    {categories &&
                      categories.map((category) => (
                        <option key={category.hash_id} value={category.hash_id}>
                          {category.name}
                        </option>
                      ))}
                  </select>
                  {formik.touched.category_id && formik.errors.category_id && (
                    <p className="err mb-0 ">{formik.errors.category_id}</p>
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center mb-3">
                <label>Lot Meta Title: </label>
                <div className="w-100">
                  <input
                    type="text"
                    placeholder="Lot meta title"
                    name="lot_meta_title"
                    value={formik.values.lot_meta_title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.lot_meta_title &&
                    formik.errors.lot_meta_title && (
                      <p className="err mb-0 ">
                        {formik.errors.lot_meta_title}
                      </p>
                    )}
                </div>
              </div>
              <div className="d-flex align-items-center mb-3">
                <label>Lot Meta Description: </label>
                <div className="w-100">
                  <textarea
                    placeholder="Lot meta description"
                    rows={3}
                    name="lot_meta_description"
                    id="lot_meta_description"
                    value={formik.values.lot_meta_description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  ></textarea>
                  {formik.touched.lot_meta_description &&
                    formik.errors.lot_meta_description && (
                      <p className="err mb-0 ">
                        {formik.errors.lot_meta_description}
                      </p>
                    )}
                </div>
              </div>
              <div className="image-preview-container d-flex align-item-center gap-3 mb-2">
                {images.length > 0 ? (
                  images.map((image, index) => (
                    <div key={index} className="image-preview">
                      <img
                        src={URL.createObjectURL(image)}
                        alt={`Preview ${index + 1}`}
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                          marginRight: "10px",
                        }}
                      />
                    </div>
                  ))
                ) : (
                  <p>No images uploaded.</p>
                )}
              </div>
              <div className="d-flex align-items-center mb-3">
                <Button
                  component="label"
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                  className="w-45">
                  Upload Image
                  <input
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    id="images-upload"
                    multiple
                    name="images"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                </Button>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-primary"
                  disabled={isSubmitting}
                  type="submit"
                >
                  {!isSubmitting ? "Submit" : "Submitting..."}
                </button>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default NewLot;
