import React, { useEffect, useState } from "react";
import axios from "axios";
import "./AuctionCategories.css";
import { Link, useLocation } from "react-router-dom";
import demo from "../../../assets/images/demo.jpg";

const AuctionCategories = () => {
  const [categories, setCategories] = useState([]);
  const location = useLocation();

  const getHashidFromPath = () => {
    const pathname = window.location.pathname;
    const segments = pathname.split("/");
    return segments[segments.length - 1];
  };

  const getNamefrompth = () => {
    const pathname = window.location.pathname;
    const segments = pathname.split("/");
    return segments[segments.length - 2];
  };

  const hashid = getHashidFromPath();
  // console.log("Extracted hashId:", hashid);

  const name = getNamefrompth();
  // console.log("Extracted name:", name);

  /*------------get Auction category-------------*/
  useEffect(() => {
    const fetchAuctions = async () => {
      try {
        await axios
          .get(
            `${process.env.REACT_APP_API_URL}/get/auctions?category=${hashid}`,
            {
              headers: {
                "ngrok-skip-browser-warning": "69420",
              },
            }
          )
          .then((result) => {
            setCategories(result.data.data);
          });
        // console.log(response.data.data, "auction response")
      } catch (error) {
        console.error("Error fetching categories:");
      } finally {
      }
    };
    fetchAuctions();
  }, [location]);

  return (
    <>
      <div className="state-auction">
        <div className="container-fluid">
          <div className="row auction-home">
            <p className="text-start pt-2 px-2">
              HOME
              <span> &gt; </span>
              <span>Categories</span>
              <span> &gt; </span>              
                <span>{decodeURIComponent(name)}</span>              
            </p>
          </div>
        </div>
        {/* <CatogiryList /> */}
        <div className="container mt-4 mb-4">
          <div className="row">
            {categories && categories.length > 0 ? (
              <h3 className="mb-3">{decodeURIComponent(name)} for Sale</h3>
            ) : (
              <p></p>
            )}
            {categories && categories.length > 0 ? (
              categories.map((category, index) => (
                <div className="col-12 col-md-3 col-lg-4" key={index}>
                  <div className="card p-3 mb-4" style={{ height: "420px" }}>
                    <img
                      className="card-img-top"
                      style={{
                        width: "100%",
                        height: "250px",
                        objectFit: "contain",
                      }}
                      src={
                        category.auction_images.length > 0
                          ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${category?.auction_images?.[0]?.image_path}`
                          : demo
                      }
                      alt={category?.name}
                    />
                    <div className="card-body">
                      <div className="card-title">
                        <h5
                          className="title-text font-bold"                          
                          style={{
                            WebkitLineClamp: 1,
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            color: "#007BFF"
                          }}
                        >
                          {category?.name}
                        </h5>
                      </div>
                      <p className="font-semibold">
                        {category?.lots?.length} Lots - End{" "}
                        {new Date(category?.end_date).toLocaleDateString()}
                      </p>
                      <Link to={`/auctions/${category?.hash_id}`}>
                        <button className="btn btn-primary">
                          View Details
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-12 text-center mt-4">
                <h5>No categories found</h5>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default AuctionCategories;
