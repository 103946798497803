import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import axios from "axios";
import "./Subscribe.css";
import { toast, ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";

function Subscribe() {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const url = window.location.href;


  const handleSubmit = async (e) => {
    setIsSubmitting(true);
    e.preventDefault();
    try {
      await axios.post(
       `${process.env.REACT_APP_API_URL}/subscribe`,
        {
          email: email,
        }
      )
      .then((response) => {
        toast.success("Subscription successful! ");
        setEmail("");
        setIsSubmitting(false);
      })
    } catch (err) {
      console.error(err);
      setIsSubmitting(false);
      const errorMessage =
        err.response && err.response.data && err.response.data.error && err.response.data.error.email
          ? err.response.data.error.email[0]
          : "Subscription failed. Please try again later.";
      toast.error(errorMessage);
    }
  };

  return (
    <>
      <Helmet>
        <title>Subscribe to Our Auction Update Newsletter - Auctionslive</title>
        <meta name="description" content="Sign up to receive our weekly Auction Update emails with the latest on upcoming auctions, special events, and exclusive listings. Stay informed every Tuesday and Saturday – subscribe now and never miss out!" />
        <meta property="og:title" content="Subscribe to Our Auction Update Newsletter - Auctionslive" />
        <meta property="og:description" content="Sign up to receive our weekly Auction Update emails with the latest on upcoming auctions, special events, and exclusive listings. Stay informed every Tuesday and Saturday – subscribe now and never miss out!" />
        <meta property="og:url" content={url} />
        <link rel="canonical" href={url} />
      </Helmet>
      <ToastContainer />
      <section className="subscribe mt-4">
        <div className="container">
          <h1 style={{ fontSize: "26px" }}>Subscribe to our Newsletter</h1>
          <p>
            To receive our weekly Auction Update emails, please complete the
            form below and click "Subscribe." Our email updates are typically
            sent every Tuesday and Saturday. Thank you for your interest in our
            auctions!
          </p>
          <Form onSubmit={handleSubmit} className="mt-5 mb-4">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              className="subscribe-btn ps-5 pe-5"
              disabled={isSubmitting}>
              {isSubmitting ? "Subscribe..." : "Subscribe"}
            </Button>
          </Form>
        </div>
      </section>
    </>
  );
}

export default Subscribe;
