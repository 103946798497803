import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "./BiddType.css";
import Editbidding from "./Editbidding";
import MUIDataTable from "mui-datatables";
import { CircularProgress } from "@mui/material";
import { Edit } from "@mui/icons-material";

const BiddingTypes = () => {
  const [biddingTypes, setBiddingTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editShow, setEditShow] = useState(false);
  const [editid, setEditid] = useState(null);
  const accessToken = localStorage.getItem("access_token");
  const [data, setData] = useState([]);

  const fetchBiddingTypes = () => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      setError("Access token not found.");
      setLoading(false);
      return;
    }
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "ngrok-skip-browser-warning": "69420",
    };
    axios
      .get(`${process.env.REACT_APP_API_URL}/admin/bidding-types`, { headers })
      .then((response) => {
        setBiddingTypes(response.data.data);
        // console.log(response.data.data)
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchBiddingTypes();
  }, []);

  const handleEditBlog = (id) => {
    setEditid(id);
    setEditShow(true);
    // console.log(`Edit blog with ID: ${id}`);
  };

  // Fetch bidding data when editid changes (only when it is set)
  useEffect(() => {
    if (editid) {
      fetchBidding();
    }
  }, [editid]);

  const fetchBidding = () => {
    if (!editid) return; // Prevent API call if no ID is set
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/admin/bidding-types/edit/${editid}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      )
      .then((response) => {
        // console.log(response.data.data, "bidding");
        setData(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching bidding data:", error);
      });
  };

  const columns = [
    {
      name: "number",
      label: "SrNo.",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (_value, tableMeta) => {
          return tableMeta.rowIndex + 1;
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "is_active",
      label: "Status",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return value === 0 ? "Disable" : "Enable";
        },
      },
    },
    {
      name: "hash_id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <>
              <div className="d-flex flex-wrap align-items-center gap-2">
                <p
                  style={{ color: "#2695FF", fontWeight: "bold", cursor: "pointer" }}
                  onClick={() => handleEditBlog(value)}>
                  <Edit/>
                </p>
              </div>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    viewColumns: false,
    selectableRows: "none",
    responsive: "standard",
    download: false,
    print: false,
    // pagination: false,
    textLabels: {
      body: {
        noMatch: "No User Found",
      },
    },
  };

  return (
    <>
      <section className="pt-3 mb-3">
        <div className="container ">
          <div className="row">
            {loading ? (
              <div className="d-flex justify-content-center">
                <CircularProgress />
              </div>
            ) : (
              <div className="col-12">
                {/* <h2>Bidding Types</h2> */}
                <MUIDataTable
                  title={"Bidding"}
                  data={biddingTypes}
                  columns={columns}
                  options={options}
                />
              </div>
            )}
          </div>
        </div>
      </section>
      <Editbidding
        open={editShow}
        onClose={() => setEditShow(false)}
        data={data}
        id={editid}
        fetchBidding={fetchBiddingTypes}
      />
    </>
  );
};

export default BiddingTypes;
