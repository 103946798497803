import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import demo from "../../../assets/images/demo.jpg";

function Biddingtype() {
  const [lotTypes, setLotTypes] = useState([]);
  const location = useLocation();
  const [remainingTime, setRemainingTime] = useState({});

  useEffect(() => {
    const currentURL = location.pathname;
    const biddingType = currentURL.split("/").pop();

    const fetchLotTypes = async () => {
      try {
        await axios
          .get(
            `${process.env.REACT_APP_API_URL}/get/auctions?filter=${biddingType}`,
            {
              headers: {
                "ngrok-skip-browser-warning": "69420",
              },
            }
          )
          .then((response) => {
            if (response.data && response.data.data) {
              setLotTypes(response.data.data);
            } else {
              setLotTypes([]); // Set empty array to prevent undefined issues
            }
            // console.log(response.data.data, "lot type");
          });
      } catch (error) {
        console.error("Error fetching lot types:", error);
      }
    };
    fetchLotTypes();
  }, [location]);

  // const calculateDaysLeft = (endDate) => {
  //   const timeLeft = new Date(endDate) - new Date();
  //   return timeLeft > 0 ? Math.floor(timeLeft / (1000 * 60 * 60 * 24)) : 0;
  // };

  useEffect(() => {
    const interval = setInterval(() => {
      const newTimes = {};
      lotTypes.forEach((lot) => {
        newTimes[lot.hash_id] = calculateRemainingTime(lot.end_date);
      });
      setRemainingTime(newTimes);
    }, 1000);

    return () => clearInterval(interval);
  }, [lotTypes]);

  const calculateRemainingTime = (endDate) => {
    const now = new Date();
    const end = new Date(endDate);
    const diff = end - now;

    if (diff > 0) {
      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);
      return `${hours}h ${minutes}m ${seconds}s`;
    }
    return "Closed";
  };

  const calculateDaysLeft = (endDate) => {
    const now = new Date();
    const end = new Date(endDate);
    const diff = end - now;
    return Math.floor(diff / (1000 * 60 * 60 * 24));
  };

  return (
    <section className="biddingtype mt-5 mb-5">
      <div className="container">
        <div className="row g-5">
          {lotTypes?.length > 0 ? (
            lotTypes &&
            lotTypes.map((lotType, index) => (
              <div className="col-12 col-md-3 col-lg-4" key={index}>
                <div className="card">
                  <div className="card-img">
                    <img
                      src={
                        lotType?.auction_images?.length > 0
                          ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${lotType?.auction_images[0]?.image_path}`
                          : demo
                      }
                      alt={lotType.name}
                      className="img-fluid"
                      style={{
                        height: "250px",
                        width: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                  <div className="card-body">
                    <h5 className="card-title text-capitalize">
                      {lotType?.name}
                    </h5>
                    <div className="d-flex justify-content-between align-items-center">
                      <h6>{lotType?.lots?.length} Lots</h6>
                      <h6 className="ms-3">
                        {lotType?.bidding_type_id?.name || "Unknown"}
                      </h6>
                    </div>
                    {/* <h6 className="" style={{ color: "#2695FF" }}>
                      {new Date(lotType?.end_date) > new Date()
                        ? `${calculateDaysLeft(lotType?.end_date)} days left`
                        : "Closed"}
                    </h6> */}
                    <span
                      className="font-semibold"
                      style={{ color: "#2695FF" }}
                    >
                      {new Date(lotType?.end_date) > new Date()
                        ? calculateDaysLeft(lotType?.end_date) > 1
                          ? `${calculateDaysLeft(lotType?.end_date)} days left`
                          : `${
                              remainingTime[lotType.hash_id] || "Time..."
                            } left`
                        : "Closed"}
                    </span>
                    <Link
                      to={`/auctions/${lotType.hash_id}`}
                      className="text-decoration-none mt-3 mb-2"
                    >
                      <button className="btn btn-primary">View Details</button>
                    </Link>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No lot types available</p>
          )}
        </div>
      </div>
    </section>
  );
}

export default Biddingtype;
