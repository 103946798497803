import {
  Box,
  Checkbox,
  FormControlLabel,
  Modal,
  TextField,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { useFormik } from "formik";
import { toast } from "react-toastify";

function Editbidding({ open, onClose, data, id, fetchBidding }) {
  const [isSending, setIsSending] = React.useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data?.name || "",
      description: data?.description || "",
      isEnabled: data?.is_active === 1,
    },
    onSubmit: async (values) => {
      try {
        setIsSending(true);
        const accessToken = localStorage.getItem("access_token");
        if (!accessToken) {
          console.error("Access token not found in localStorage.");
          return;
        }
        const apiHeaders = {
          Authorization: `Bearer ${accessToken}`,
          "ngrok-skip-browser-warning": "69420",
        };

        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("description", values.description);
        formData.append("is_active", values.isEnabled ? 1 : 0);

       await axios.post(
          `${process.env.REACT_APP_API_URL}/admin/bidding-types/update/${id}`,
          formData,
          { headers: apiHeaders }
        )
        .then((response) => {
          // console.log("Bidding updated:", response);
          onClose();
          fetchBidding()
          toast.success("Bidding updated successfully!");
        })
      } catch (error) {
        console.error("Error updating bidding:", error);
        toast.error("Error updating bidding");
      } finally {
        setIsSending(false); // Hide loading state
      }
    },
  });

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: 800,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          overflowY: "auto",
          maxHeight: "90vh",
        }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <h2>Edit Bidding Types</h2>
          <CloseIcon onClick={onClose} sx={{ fontSize: "20px", cursor: "pointer" }} />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            className="mb-4 w-100"
            id="name"
            label="Name"
            variant="standard"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
          />
          <TextField
            className="w-100"
            id="description"
            label="Description"
            variant="standard"
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="isEnabled"
                checked={formik.values.isEnabled}  
                onChange={formik.handleChange}    
              />
            }
            label="Enable"
          />
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-secondary mt-3 me-2"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary mt-3"
              disabled={isSending}
            >
              {isSending ? "Updating..." : "Update"}
            </button>
          </div>
        </form>
      </Box>
    </Modal>
  );
}

export default Editbidding;
