import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./LotsDetails.css";
import demo from "../../../assets/images/demo.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Magnifier from "react-magnifier";
import LotModal from "../Category/Lots/LotModal";
import moment from "moment";
import { RingLoader } from "react-spinners";
import Chat from "./Chat";
import { Checkbox } from "@mui/material";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import { Helmet } from "react-helmet";
import Signinmodel from "../Siginmodel/Signinmodel";
import { Link } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const LotsDetails = () => {
  const [lotData, setLotData] = useState(null);
  const [lotImage, setLotimage] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSection, setOpenSection] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const { hash_id } = useParams();
  const [showwatch, setShowwatch] = useState(false);
  const token = localStorage.getItem("access_token");
  const [isAdded, setIsAdded] = useState(false);
  const url = window.location.href;

  const [open, setOpen] = useState(false);
  const handleClosed = () => setOpen(false);

  useEffect(() => {
    if (token) {
      setShowwatch(true);
    } else {
      setShowwatch(false);
    }
  }, []);

  useEffect(() => {
    const fetchWatchlistStatus = async () => {
      try {
        await axios
          .get(`${process.env.REACT_APP_API_URL}/lots/${hash_id}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const user_id = parseInt(localStorage.getItem("user_id"));
            const watchlists = response.data.lots.watchlists || [];
            const addedLotIds = watchlists
              .filter(
                (item) => item.is_active === 1 && item.user_id === user_id
              )
              .map((item) => item.lot_id);
            setIsAdded(addedLotIds.length > 0);
          });
      } catch (error) {
        console.error("Error fetching watchlist:", error);
      }
    };
    fetchWatchlistStatus();
  }, [token, hash_id]);

  useEffect(() => {
    const fetchlotimage = async () => {
      try {
        await axios
          .get(`${process.env.REACT_APP_API_URL}/get/lotimge/${hash_id}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            // console.log(response.data.lot_images, "lotimage");
            setLotimage(response.data.lot_images);
          });
      } catch (error) {
        console.error("Error fetching:", error);
      }
    };

    fetchlotimage();
  }, [token, hash_id]);

  const handleWatchlistToggle = async () => {
    try {
      if (isAdded) {
        await axios
          .get(`${process.env.REACT_APP_API_URL}/watchlist/remove/${hash_id}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            // console.log("Item removed from watchlist successfully!");
            setIsAdded(false);
          });
      } else {
        await axios
          .get(`${process.env.REACT_APP_API_URL}/watchlist/add/${hash_id}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            // console.log("Item added to watchlist successfully!");
            setIsAdded(true);
          });
      }
    } catch (error) {
      console.error(
        "An error occurred while toggling watchlist status:",
        error
      );
    }
  };

  useEffect(() => {
    const fetchLotData = async () => {
      try {
        await axios
          .get(`${process.env.REACT_APP_API_URL}/lots/${hash_id}`)
          .then((response) => {
            setLotData(response.data);
            // console.log(response.data, "scscsdsdcsd")
          });
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    fetchLotData();
  }, [hash_id]);

  if (loading)
    return (
      <div className="w-full flex items-center justify-center py-3">
        <RingLoader color="#36d7b7" size={100} />
      </div>
    );
  if (error) return <div>Error: {error.message}</div>;

  const defaultImage = demo;

  const {
    auction,
    bids = [],
    shipping_available,
    reserve_price,
    highest_bidder,
    estimate_from,
    estimate_to,
  } = lotData?.lots || {};
  const handleToggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  const incrementAmount = auction?.bid_increments[0]?.increment_amount;

  // console.log(auction?.bid_increments[0]?.increment_amount)

  const firstWinningBid = Array.isArray(bids)
  ? [...bids].reverse().find((bid) => bid.status === "Winning")?.amount || "No winning bid"
  : 0;

// Find the latest winning bid
const latestWinningBid = Array.isArray(bids)
  ? bids.find((bid) => bid.status === "Winning")?.amount || firstWinningBid
  : firstWinningBid;
  

  const winningBidAmount = bids.length > 0 
  ? Number(latestWinningBid) + Number(incrementAmount) 
  : firstWinningBid;

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const endDate = moment(auction?.end_date);
  const isClosed = endDate.isBefore(moment());
  const timeLeft = endDate.diff(moment(), "days");

  const calculateDaysLeft = (endDate) => {
    const timeLeft = new Date(endDate) - new Date();
    return timeLeft > 0 ? Math.floor(timeLeft / (1000 * 60 * 60 * 24)) : 0;
  };

  const sliderSettings1 = {
    asNavFor: nav2,
    ref: (slider) => setNav1(slider),
    dots: true,
    infinite: lotImage.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const sliderSettings2 = {
    asNavFor: nav1,
    ref: (slider) => setNav2(slider),
    slidesToShow: lotImage.length > 4 ? 4 : lotImage.length,
    swipeToSlide: true,
    focusOnSelect: true,
    centerMode: lotImage.length > 4,
    infinite: lotImage.length > 4,
  };

  const handleOpenLoginModal = () => {
    setOpen(true);
  };

  const handleClick = () => {
    const isLoggedIn = !!localStorage.getItem("access_token");
    if (!isLoggedIn) {
      handleOpenLoginModal();
    } else {
      handleOpenModal();
    }
  };

  const handleClickd = () => {
    const isLoggedIn = !!localStorage.getItem("access_token");
    if (!isLoggedIn) {
      handleOpenLoginModal();
    } else {
      handleWatchlistToggle();
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {lotData?.lots?.lot_meta_title || lotData?.lots?.title} - AuctionLive
        </title>
        <meta
          name="description"
          content={lotData.lots.lot_meta_description || lotData?.lots?.title}
        />
        <meta
          property="og:title"
          content={lotData.lots.lot_meta_title || lotData?.lots?.title}
        />
        <meta
          property="og:description"
          content={lotData.lots.lot_meta_description || lotData?.lots?.title}
        />
        <meta property="og:url" content={url} />
        <link rel="canonical" href={url} />
      </Helmet>
      <div className="lots-details pt-3">
        <div className="container">
          <div className="row my-4">
            <div className="col-md-6">
              <div className="slider-container w-full ">
                <div className="w-full object-cover">
                  <div className="lotsDetailsSlider h-[300px]">
                    <Slider {...sliderSettings1}>
                      {lotImage.map((image, index) => (
                        <div key={index} className="carousel-item active">
                          <Magnifier
                            src={
                              lotImage.length > 0
                                ? `${process.env.REACT_APP_BASE_IMAGE_URL}${image?.image_path}`
                                : demo
                            }
                            alt={`Lot Image ${index}`}
                            className=""
                            loading="lazy"
                          />
                        </div>
                      ))}
                    </Slider>
                  </div>
                  {lotImage.length > 1 && (
                    <div className="lotsDetailsSliderThumbnail h-[120px]">
                      <Slider {...sliderSettings2} className="thumbnail-slider">
                        {lotImage.map((image, index) => (
                          <img
                            key={index}
                            className="thumbnail-img"
                            src={
                              lotImage.length > 0
                                ? `${process.env.REACT_APP_BASE_IMAGE_URL}${image?.image_path}`
                                : demo
                            }
                            alt={`Thumbnail ${index}`}
                            loading="lazy"
                          />
                        ))}
                      </Slider>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="flex items-start justify-start flex-col py-3">
                <h1 className="text-capitalize lots-title">
                  {lotData?.lots?.title}
                </h1>
                {/* {!showwatch ? (
                  <span></span>
                ) : ( */}
                  <div className="d-flex align-items-center flex-row watchlist">
                    <Checkbox
                      icon={<FavoriteBorder />}
                      checkedIcon={<Favorite />}
                      checked={isAdded}
                      // onChange={handleWatchlistToggle}
                      onClick={() => handleClickd(lotData?.lots?.hash_id)}
                      size="small"
                    />
                    <span>Watchlist</span>
                  </div>
                {/* )} */}
                {/* <div className="flex items-start gap-5 justify-start w-full"> */}
                <div className="w-100">
                  <div className="d-flex justify-content-between align-items-center item-start">
                    <p>
                      <span className="font-bold">Total Bids: </span>{" "}
                      {bids.length > 0 ? bids.length : 0}
                    </p>
                    <p>
                      <span className="font-bold">Time Remaining : </span>{" "}
                      {new Date(auction?.end_date) > new Date()
                        ? calculateDaysLeft(auction?.end_date) > 1
                          ? `${calculateDaysLeft(auction?.end_date)} days left`
                          : "Last day"
                        : "Closed"}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center item-start">
                    <p>
                      <span className="font-bold">Date: </span>
                      {new Date(
                        auction?.start_date
                      ).toLocaleDateString()} -{" "}
                      {new Date(auction?.end_date).toLocaleDateString()}
                    </p>
                    <p>
                      <span className="font-bold">Shipping Available : </span>
                      {shipping_available ? "Yes" : "No"}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center item-start">
                    {auction?.city?.name &&
                      auction?.state?.name &&
                      auction?.country?.name && (
                        <p className="mb-0">
                          <span className="font-bold ">Address : </span>
                          {auction?.city?.name}, {auction?.state?.name},{" "}
                          {auction?.country?.name}
                        </p>
                      )}
                    {reserve_price != null &&
                      ((highest_bidder?.amount || 0) > reserve_price ? (
                        <p className="reserve-text text-success font-semibold mb-0">
                          <CheckIcon /> Reserve Met
                        </p>
                      ) : (
                        <p className="reserve-text text-danger font-semibold mb-0">
                          <CloseIcon /> Reserve Not Met
                        </p>
                      ))}
                  </div>
                </div>
                <div className="w-100 mt-2">
                  <h4>AUCTIONEER INFORMATION :</h4>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="text-capitalize">
                      <span className="font-bold">Company: </span>
                      <Link
                        to={`/companysearch/${auction?.user_details?.id}/${auction?.user_details?.company_slug}`}
                        className="text-decoration-none text-dark"
                      >
                        {auction?.user_details?.company}
                      </Link>
                    </p>
                    <p>
                      <span className="font-bold text-capitalize">Email: </span>
                      <a
                        href={`mailto:${auction?.created_by?.email}`}
                        className="text-decoration-none text-dark"
                      >
                        {auction?.created_by?.email}
                      </a>
                    </p>
                  </div>
                  {/* <div className="d-flex justify-content-between align-items-center"> */}
                  <p>
                    <span className="font-bold text-capitalize">Phone: </span>
                    <a
                      href={`tel:${auction?.user_details?.phone1}`}
                      className="text-decoration-none text-dark"
                    >
                      {auction?.user_details?.phone1}
                    </a>
                  </p>
                  {/* </div> */}
                </div>
                {/* </div> */}
                <div className="d-flex justify-content-between align-items-center w-100 gap-3">
                  {isClosed ? (
                    <button className="btn btn-secondary w-50" disabled>
                      Closed
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary w-50"
                      onClick={handleClick}
                    >
                      Bid <span>{winningBidAmount}</span>
                    </button>
                  )}
                  <Link to={`/auctions/${auction?.hash_id}`} className="w-50">
                    <button className="btn btn-secondary w-100">
                      View Catalog
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="container">
              <Chat hashId={hash_id} />
            </div>
          </div>
          <div className="row pt-5">
            <div className="col-md-12">
              <div className="collapse-section">
                <button
                  className="collapse-button"
                  onClick={() => handleToggleSection("information")}
                >
                  <span>Information</span>
                  <span>
                    <i className="fa-solid fa-angle-down"></i>
                  </span>
                </button>
                {openSection === "information" && (
                  <div className="px-4 py-1">
                    <p>Lot Number: {lotData?.lots?.lot_number}</p>
                    <p>Description: {lotData?.lots?.description}</p>
                  </div>
                )}
              </div>
              <div className="collapse-section">
                <button
                  className="collapse-button"
                  onClick={() => handleToggleSection("auction-info")}
                >
                  <span>Auction Information</span>
                  <span>
                    <i className="fa-solid fa-angle-down"></i>
                  </span>
                </button>
                {openSection === "auction-info" && (
                  <div className="px-4 py-1">
                    <p className="text-capitalize">Name: {auction?.name}</p>
                    <p>Auctioneer: {auction?.auctioneer}</p>
                    <p>Type: {auction?.bidding_type_id?.name}</p>
                    <p>
                      Date(s):{" "}
                      {new Date(auction?.start_date).toLocaleDateString()} -{" "}
                      {new Date(auction?.end_date).toLocaleDateString()}
                    </p>
                    {/* <p>Location: {auction?.location}</p> */}
                    <p>Buyer Premium: {auction?.buyer_premium}%</p>
                    <p>Description: {auction?.description}</p>
                  </div>
                )}
              </div>
              <div className="collapse-section">
                <button
                  className="collapse-button"
                  onClick={() => handleToggleSection("terms-conditions")}
                >
                  <span>Terms and Conditions</span>
                  <span>
                    <i className="fa-solid fa-angle-down"></i>
                  </span>
                </button>
                {openSection === "terms-conditions" && (
                  <div className="px-4 py-1">
                    <p>{auction?.terms_and_conditions}</p>
                  </div>
                )}
              </div>
              <div className="collapse-section">
                <button
                  className="collapse-button"
                  onClick={() => handleToggleSection("bid-increments")}
                >
                  <span>Bid Increments</span>
                  <span>
                    <i className="fa-solid fa-angle-down"></i>
                  </span>
                </button>
                {openSection === "bid-increments" && (
                  <div className="px-4 py-1">
                    <p>Estimate From: {estimate_from}</p>
                    <p>Estimate To: {estimate_to}</p>
                    {auction?.bid_increments.map((increment, index) => (
                      <div key={index}>
                        <p>Increment Amount: {increment?.increment_amount}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="collapse-section">
                <button
                  className="collapse-button"
                  onClick={() => handleToggleSection("payment-info")}
                >
                  <span>Payment Information</span>
                  <span>
                    <i className="fa-solid fa-angle-down"></i>
                  </span>
                </button>
                {openSection === "payment-info" && (
                  <div className="px-4 py-1">
                    <p>Currency: {auction?.currency || "USD"}</p>
                    <p>Buyer Premium: {auction?.buyer_premium}%</p>
                  </div>
                )}
              </div>
              <div className="collapse-section">
                <button
                  className="collapse-button"
                  onClick={() => handleToggleSection("shipping")}
                >
                  <span>Shipping / Pick Up</span>
                  <span>
                    <i className="fa-solid fa-angle-down"></i>
                  </span>
                </button>
                {openSection === "shipping" && (
                  <div className="px-4 py-1">
                    <p>
                      Shipping Details:{" "}
                      {auction?.shipping_option?.pickup_info ||
                        "No information"}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {isModalOpen && (
          <LotModal
            isOpen={isModalOpen}
            lot={lotData?.lots}
            onClose={handleCloseModal}
          />
        )}
      </div>
      <Signinmodel open={open} handleClose={handleClosed} />
    </>
  );
};

export default LotsDetails;
