import axios from "axios";
import MUIDataTable from "mui-datatables";
import React, { useCallback, useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Mailform from "./Mailform";
import { CircularProgress } from "@mui/material";

let selectedRowIds = [];

function Mails() {
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState(true);
  const [perPage, setPerPage] = useState(50);
  const [isRowSelected, setIsRowSelected] = useState(false);
  // const [rows, setRows] = useState();
  const [open, setOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  // const handleClosed = () => setOpen(false);
  const handleClosed = () => {
    selectedRowIds = [];
    setIsRowSelected(null); 
    setOpen(false);
    setSelectedRows([])
  };

  const opennewauction = () => {
    // console.log("opennewauction");
    setOpen(true);
  };

  const fetchUser = useCallback(async (page = 1, perPage = 50) => {
    const accessToken = localStorage.getItem("access_token");
    setLoading(true);   
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/auctioner/list?page=${page}&per_page=${perPage}`,
        { headers }
      );
      // console.log(response.data.data, "response");
      const { data, current_page, last_page } = response.data.data;
      setUser(data);
      //   console.log(data, "data");
      setPagination({
        currentPage: current_page,
        lastPage: last_page,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching auctions:", error);
    }
  });

  useEffect(() => {
    fetchUser(pagination.currentPage, perPage);
  }, []);

  const columns = [
    {
      name: "number",
      label: "SrNo.",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (_value, tableMeta) => {
          return tableMeta.rowIndex + 1;
        },
      },
    },
    {
      name: "username",
      label: "Username",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "is_active",
      label: "Status",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return value === 0 ? "Pending" : "Active";
        },
      },
    },
  ];

  // const options = {
  //   filter: false,
  //   viewColumns: false,
  //   // selectableRows: "none",
  //   responsive: "standard",
  //   download: false,
  //   print: false,
  //   pagination: false,
  //   textLabels: {
  //     body: {
  //       noMatch: "No User Found",
  //     },
  //   },
  //   isRowSelectable: (dataIndex) => true,
  //   onRowSelectionChange: (
  //     currentRowsSelected,
  //     allRowsSelected,
  //     rowsSelected
  //   ) => {
  //     const rowIds = allRowsSelected.map((row) => data[row.dataIndex].hash_id);
  //     // selected(rowIds);
  //     selectedRowIds = rowIds;
  //     // console.log("Selected Row IDs:", rowIds);
  //     setIsRowSelected(selectedRowIds.length > 0);
  //   },
  // };


  const options = {
    filter: false,
    viewColumns: false,
    responsive: "standard",
    download: false,
    print: false,
    pagination: false,
    textLabels: {
      body: {
        noMatch: "No Auctioneer Found",
      },
    },
    rowsSelected: selectedRows, 
    onRowSelectionChange: (_currentRowsSelected, allRowsSelected) => {
      const rowIds = allRowsSelected.map((row) => user[row.dataIndex].hash_id);
      selectedRowIds = rowIds;
      setIsRowSelected(rowIds.length > 0);
      setSelectedRows(rowIds.length > 0 ? allRowsSelected.map((row) => row.dataIndex) : []);
    },
  };

  const handlePreviousPage = () => {
    if (pagination.currentPage > 1) {
      fetchUser(pagination.currentPage - 1, perPage);
    }
  };

  const handleNextPage = () => {
    if (pagination.currentPage < pagination.lastPage) {
      fetchUser(pagination.currentPage + 1, perPage);
    }
  };

  //  const data =
  //   rows &&
  //   rows.map((row) => ({
  //     hash_id: row.hash_id,
  //   }));

  return (
    <>
      <section className="mails mt-5">
        <div className="container">
          <div className="row">
            {loading ? (
              <div className="d-flex justify-content-center">
                <CircularProgress />
              </div>
            ) : (
              <div className="col-12">
                {isRowSelected && (
                  <button
                    className="btn btn-primary"
                    style={{ marginBottom: "16px" }}
                    //   onClick={handleSend}
                    onClick={opennewauction}>
                   Send Email
                  </button>
                )}
                <MUIDataTable
                  title={"Auctioneer"}
                  data={user}
                  columns={columns}
                  options={options}
                />
                <div className="d-flex justify-content-end align-items-center pagination">
                  {pagination && (
                    <div className="d-flex align-items-center pagi">
                      <button
                        onClick={handlePreviousPage}
                        disabled={pagination.currentPage === 1}
                        cursor="pointer">
                        <ArrowBackIosIcon />
                      </button>
                      <span className="font-semibold ">
                        {pagination.currentPage} of {pagination.lastPage}
                      </span>
                      <button
                        onClick={handleNextPage}
                        disabled={
                          pagination.currentPage === pagination.lastPage
                        }
                        cursor="pointer">
                        <ArrowForwardIosIcon />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <Mailform
        open={open}
        handleClose={handleClosed}
        rowsid={selectedRowIds}
      />
    </>
  );
}

export default Mails;
