import React, { useEffect, useState } from "react";
import axios from "axios";
import { RingLoader } from "react-spinners";
import Book8 from "../../../assets/images/demo.jpg";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "@mui/material";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import Signinmodel from "../Siginmodel/Signinmodel";

const Picks = () => {
  const [auctions, setAuctions] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("access_token");
  const [remainingTimes, setRemainingTimes] = useState({});
  const [open, setOpen] = useState(false);
  const handleClosed = () => setOpen(false);
  const [showwatch, setShowwatch] = useState(false);
  const navigator = useNavigate();
  const [watchlistMap, setWatchlistMap] = useState({});

  const fetchAuctions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auctions/status/top-pickups`,
        { headers: { "ngrok-skip-browser-warning": "69420" } }
      );
      const displayedAuctions = response.data.data?.slice(0, 8);
      setAuctions(displayedAuctions);
      // console.log("top pick", displayedAuctions);
    } catch (error) {
      console.error("Error fetching auctions:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAuctions();
  }, []);

  useEffect(() => {
    // Update countdown every second
    const interval = setInterval(() => {
      const updatedTimes = {};
      auctions.forEach((auction) => {
        updatedTimes[auction.hash_id] = getRemainingTime(
          auction.auction?.end_date
        );
      });
      setRemainingTimes(updatedTimes);
    }, 1000);

    return () => clearInterval(interval);
  }, [auctions]);

  const getRemainingTime = (endDate) => {
    const now = new Date();
    const end = new Date(endDate);
    const diff = end - now;

    if (diff <= 0) return "Closed";

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    if (days > 1) {
      return `${days} Days Left`;
    } else {
      const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((diff / (1000 * 60)) % 60);
      const seconds = Math.floor((diff / 1000) % 60);
      return `${hours}h ${minutes}m ${seconds}s left`;
    }
  };

  useEffect(() => {
    if (token) {
      setShowwatch(true);
    } else {
      setShowwatch(false);
    }
  }, []);



  useEffect(() => {
    if (!token) return;

    const storedUserId = Number(localStorage.getItem("user_id"));
    if (!storedUserId) return;

    const newWatchlistMap = {};

    auctions.forEach((auction) => {
      const isAdded = auction.watchlists?.some(
        (item) => item.is_active === 1 && Number(item.user_id) === storedUserId
      );
      newWatchlistMap[auction.hash_id] = isAdded;
    });

    setWatchlistMap(newWatchlistMap);
  }, [auctions, token]);



  const handleWatchlistToggle = async (hash_id) => {
    try {
      const isCurrentlyAdded = watchlistMap[hash_id];

      if (isCurrentlyAdded) {
        await axios.get(`${process.env.REACT_APP_API_URL}/watchlist/remove/${hash_id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
      } else {
        await axios.get(`${process.env.REACT_APP_API_URL}/watchlist/add/${hash_id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
      }
      // Update state correctly
      setWatchlistMap((prev) => ({
        ...prev,
        [hash_id]: !isCurrentlyAdded,
      }));
    } catch (error) {
      console.error("Error toggling watchlist:", error);
    }
  };

  const handleOpenLoginModal = () => {
    setOpen(true);
  };

  const handleClick = (hash_id) => {
    const isLoggedIn = !!localStorage.getItem("access_token");
    if (!isLoggedIn) {
      handleOpenLoginModal();
    } else {
      navigator(`/lots/${hash_id}`);
      handleWatchlistToggle(hash_id)
    }
  };

  const handleClickd = (hash_id) => {
    const isLoggedIn = !!localStorage.getItem("access_token");
    if (!isLoggedIn) {
      handleOpenLoginModal();
    } else {
      // navigator(`/lots/${hash_id}`);
      handleWatchlistToggle(hash_id)
    }
  };
 

  return (
    <>
      <div className="home">
        <div className="container">
          <div className="row">
            <div className="auctions mt-3 mb-3">
              <p className="title">Auctionslive</p>
              <p className="subtitle">Live & Online Auctions.</p>
            </div>
          </div>
          <div className="row align-items-center mt-3">
            <div className="col-md-6 col-sm-6">
              <h4 className="hot-auctions mt-2">Top Picks For You</h4>
            </div>
          </div>
          <div className="row mt-3">
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100vh" }}>
                <RingLoader color="#3498db" loading={loading} size={150} />
              </div>
            ) : auctions.length > 0 ? (
              auctions.map((auction) => (
                <div className="col-md-3 mb-4" key={auction.hash_id}>
                  <div className="card woodburn-card">
                    <div className="card-body woodburn1">
                      <div style={{ height: "75px" }}>
                        <p
                          className="card-text text-center woodburn-text text-capitalize mb-0"
                          style={{
                            WebkitLineClamp: 2,
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            height: "40px",
                          }}>
                          {auction?.title || "Auction Title Not Available"}
                        </p>
                        {/* {!showwatch ? (
                          <span></span>
                        ) : ( */}
                          <div className="d-flex align-items-center flex-row watchlist justify-content-center">
                            <Checkbox
                              icon={<FavoriteBorder />}
                              checkedIcon={<Favorite />}
                              checked={watchlistMap[auction.hash_id] || false} 
                              // onChange={() => handleWatchlistToggle(auction.hash_id)}
                              size="small"
                              onClick={() => handleClickd(auction.hash_id)}
                            />
                            <span>Watchlist</span>
                          </div>
                        {/* )} */}
                      </div>
                      <div className="woodburn1-img w-full h-[180px]">
                        <img
                          className="object-contain"
                          src={
                            auction?.images && auction?.images.length > 0
                              ? `${process.env.REACT_APP_BASE_IMAGE_URL}${auction?.auction_images[0]?.image_path}`
                              : Book8
                          }
                          alt={auction?.title || "Auction Image"}
                          loading="lazy"
                        />
                      </div>
                      <p className="mb-0 mt-3">
                        <span className="font-semibold">High Bid:</span>
                        {auction?.highest_bidder?.amount || 0}
                      </p>
                      <p className="mb-0" style={{ color: "#2695FF" }}>
                        <span>
                          {auction?.bids.length || 0}&nbsp;
                        </span>
                        Bids
                      </p>
                      <p className="font-semibold">
                        {remainingTimes[auction.hash_id] || "Calculating..."}
                      </p>
                      {/* <Link
                        to={`/lots/${auction.hash_id}`}
                        className="text-decoration-none"
                      > */}
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => handleClick(auction.hash_id)}>
                        Bid Now
                      </button>
                      {/* </Link> */}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No auctions available.</p>
            )}
          </div>
        </div>
      </div>
      <Signinmodel open={open} handleClose={handleClosed} />
    </>
  );
};

export default Picks;
