import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";

function Auctionsetting({ auction, fetchAuctions }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { id } = useParams();
  const [biddingTypes, setBiddingTypes] = useState([]);
  const [selectedBiddingId, setSelectedBiddingId] = useState("");

  /*------------get bidding type-------------*/
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/bidding-types`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data.data)) {
          setBiddingTypes(data.data);
          const initialBiddingType = data.data.find(
            (bid) => bid.name === auction?.bidding_type_id?.name
          );
          if (initialBiddingType) {
            setSelectedBiddingId(initialBiddingType.hash_id);
            formik.setFieldValue("bidding_type_id", initialBiddingType.hash_id);
          }
        } else {
          console.error("Expected an array but received:", data);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleChange = (event) => {
    const selectedId = event.target.value;
    setSelectedBiddingId(selectedId);
    formik.setFieldValue("bidding_type_id", selectedId);
  };

  // --------Edit auction details
  const validationSchema = Yup.object().shape({
    increment_amount: Yup.string().required("Increment Amount is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: auction?.name || "",
      auctioneer: auction?.auctioneer || "",
      start_date: auction?.start_date || "",
      end_date: auction?.end_date || "",
      buyer_premium: auction?.buyer_premium || "",
      description: auction?.description || "",
      deposit_amount: auction?.deposit_amount || "",
      country: auction?.country?.hash_Id || "",
      state: auction?.state?.hash_Id || "",
      city: auction?.city?.hash_Id || "",
      zip_code: auction?.zip_code || "",
      terms_and_conditions: auction?.terms_and_conditions || "",
      auction_images: [],
      min_amount: auction?.bid_increments[0]?.min_amount || "0",
      max_amount: auction?.bid_increments[0]?.max_amount || "0",
      increment_amount: auction?.bid_increments[0]?.increment_amount || "",
      bidding_notice: auction?.bidding_notice || "",
      bidding_type_id: auction?.bidding_type?.hash_id || "",
      meta_title: auction?.meta_title || "",
      meta_description: auction?.meta_description || "",
      is_shipping_available:
        auction?.shipping_option?.is_shipping_available || 0,
      pickup_info: auction?.shipping_option?.pickup_info || "",
      status: auction?.status || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // console.log(values, "form values");
      setIsSubmitting(true);
      try {
        const accessToken = localStorage.getItem("access_token");
        if (!accessToken) {
          console.error("Access token not found in localStorage.");
          return;
        }
        const apiHeaders = {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        };
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/updates/auctions/${id}`,
            values,
            { headers: apiHeaders }
          )
          .then((result) => {
            // console.log(response, "response");
            setIsSubmitting(false);
            fetchAuctions();
            toast.success("Auction Update successfully!");
          });
      } catch (error) {
        console.error("Error update auction:", error);
        setIsSubmitting(false);
        toast.error("Error update auction", error);
      }
    },
  });

  return (
    <>
    <ToastContainer/>
      <section className="lots-section detail-section" id="setting">
        <div className="container">
          <form onSubmit={formik.handleSubmit}>
            <div className="row ">
              <div className="col-12 col-md-8 ">
                <div className="border-1 rounded p-4 mb-3">
                  <h2 className="mb-3">Increment :-</h2>
                  <div className="d-flex align-items-center  mb-3">
                    <label>Increment Amount: </label>
                    <div className="d-grid w-100">
                      <input
                        type="text"
                        placeholder="Increment amount"
                        name="increment_amount"
                        id="increment_amount"
                        value={formik.values.increment_amount}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.increment_amount &&
                        formik.errors.increment_amount && (
                          <p className="err mb-0">
                            {formik.errors.increment_amount}
                          </p>
                        )}
                    </div>
                  </div>
                </div>
                <div className="border-1 rounded p-4 mb-3">
                  <h2 className="mb-3">Bidding :-</h2>
                  <div className="d-flex align-items-center mb-3">
                    <label>Bidding Notice: </label>
                    <div className="d-grid w-100">
                      <textarea
                        placeholder="Bidding Notice"
                        rows={3}
                        name="bidding_notice"
                        id="bidding_notice"
                        value={formik.values.bidding_notice}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      ></textarea>
                      {formik.touched.bidding_notice &&
                        formik.errors.bidding_notice && (
                          <p className="err mb-0">
                            {formik.errors.bidding_notice}
                          </p>
                        )}
                    </div>
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <label>Bidding Type: </label>
                    <div className="d-grid w-100">
                      <select
                        value={selectedBiddingId}
                        onChange={handleChange}
                        id="bidding_type_id"
                        name="bidding_type_id"
                        className="w-100 border-1 p-3"
                      >
                        {Array.isArray(biddingTypes) &&
                          biddingTypes.map((type) => (
                            <option key={type.hash_id} value={type.hash_id}>
                              {type.name}
                            </option>
                          ))}
                      </select>
                      {formik.touched.bidding_type_id &&
                        formik.errors.bidding_type_id && (
                          <p className="err mb-0">
                            {formik.errors.bidding_type_id}
                          </p>
                        )}
                    </div>
                  </div>
                </div>
                <div className="border-1 rounded p-4">
                  <h2 className="mb-3">Auction SEO :-</h2>
                  <div className="d-flex align-items-center mb-3">
                    <label>Meta Title: </label>
                    <input
                      type="text"
                      placeholder="Meta title"
                      name="meta_title"
                      id="meta_title"
                      value={formik.values.meta_title}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />{" "}
                  </div>
                  <div className="d-flex align-items-center  mb-3">
                    <label>Meta Description: </label>
                    <input
                      type="text"
                      placeholder="Meta description"
                      name="meta_description"
                      id="meta_description"
                      value={formik.values.meta_description}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="border-1 rounded p-4">
                  <div className="d-flex align-items-center mb-3">
                    <label className="mb-3">Status :</label>
                    <select
                      value={formik.values.status}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      id="status"
                      name="status"
                      className="w-100 border-1 p-3"
                    >
                      <option value="pending">Pending</option>
                      <option value="active">Active</option>
                    </select>
                  </div>
                  <div className="d-flex align-items-center w-100">
                    <label>Shipping :</label>
                    <div className="d-flex align-items-center">
                      <input
                        type="radio"
                        name="is_shipping_available"
                        id="is_shipping_available_yes"
                        className="mb-0"
                        value="1"
                        checked={formik.values.is_shipping_available === 1}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "is_shipping_available",
                            parseInt(e.target.value)
                          )
                        }
                        onBlur={formik.handleBlur}
                      />
                      <label
                        htmlFor="is_shipping_available_yes"
                        className="ms-2">
                        Yes
                      </label>
                    </div>
                    <div className="d-flex align-items-center ms-4">
                      <input
                        type="radio"
                        name="is_shipping_available"
                        id="is_shipping_available_no"
                        className="mb-0"
                        value="0"
                        checked={formik.values.is_shipping_available === 0}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "is_shipping_available",
                            parseInt(e.target.value)
                          )
                        }
                        onBlur={formik.handleBlur}
                      />
                      <label
                        htmlFor="is_shipping_available_no"
                        className="ms-2">
                        No
                      </label>
                    </div>
                  </div>
                  <div className="d-flex align-items-center w-100 mb-3">
                    {formik.values.is_shipping_available === 1 && (
                      <textarea
                        name="pickup_info"
                        placeholder="Pickup Info"
                        cols={30}
                        rows={3}
                        value={formik.values.pickup_info || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      ></textarea>
                    )}
                  </div>
                </div>
                <button
                  className="btn btn-primary mt-3"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {!isSubmitting ? "Update" : "Updating..."}
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}

export default Auctionsetting;
