import React, { useState } from "react";
import "./MainData.css";
import { RingLoader } from "react-spinners";
import JifImg from "../../../../assets/images/demo.jpg";

const MainData = ({ data, isLoading }) => {
  const [view, setView] = useState("list");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;

   const handleViewChange = (viewType) => {
    setView(viewType);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderItem = (item) => {
    const hasImage = item?.lot?.images && item.lot.images.length > 0;
    const imageSrc = hasImage ? item.lot.images[0].image_path : JifImg;
    const lotNumberTitle = `${item.lot.lot_number} | ${item.lot.title}`;
    const bidAmount = item.amount ? `$${item.amount}` : "Outbid";
    const winningAmount = item.status === "Winning" ? `$${item.amount}` : "";
    const shippingStatus = item.lot.shipping_available
      ? "Shipping Available"
      : "No Shipping";
    return (
      <div className="data-item">
        <div className="image-column">
          <img src={imageSrc} alt="Lot " loading="lazy"/>
        </div>
        <div className="description-column px-3">
          <p>{lotNumberTitle}</p>
        </div>
        <div className="date-column">
          <p>{new Date(item.created_at).toLocaleDateString()}</p>
        </div>
        <div className="amount-column">
          <p>
            Amount:{" "}
            <span>
              {winningAmount} {bidAmount}
            </span>
          </p>
        </div>
        <div className="shipping-column">
          <p> {shippingStatus}</p>
        </div>
      </div>
    );
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = data.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(data.length / itemsPerPage);

  return (
    <div className="main-data">
      <div className="row">
        <div className="col-md-12">
          <div className="sort-container">
            
          </div>
          {isLoading ? (
            <div className="loader-container">
              <RingLoader color={"#36D7B7"} size={60} />
            </div>
          ) : (
            <>
              <div className={`row pt-3 ${view}`}>
                {currentData && currentData.length > 0 ? (
                  currentData.map((item, index) => (
                    <div
                      key={index}
                      className={`col-md-${
                        view === "grid-2"
                          ? "6"
                          : view === "grid-12"
                          ? "4"
                          : "12"
                      }`}
                    >
                      {view === "list" && (
                        <div className="data-box-list">{renderItem(item)}</div>
                      )}
                      {view === "grid-2" && (
                        <div className="data-box-grid-2 mb-3">
                          <div className="item-header d-flex">
                            <p>{item?.lot?.lot_number} | &nbsp;</p>
                            <p>{item?.lot?.title}</p>
                          </div>
                          <div className="watchbids-image text-center">
                            <img
                              src={item?.lot.images[0]?.image_path || JifImg}
                              alt="Lot "
                              loading="lazy"
                            />
                          </div>
                          <div className="item-details text-center pt-3">
                            <p>
                              {item.status === "Winning"
                                ? `Winning Amount: $${item?.amount}`
                                : `Outbid: $${item?.amount}`}
                            </p>
                            <p>
                              {item?.lot?.shipping_available
                                ? "Shipping Available"
                                : "No Shipping"}
                            </p>
                          </div>
                        </div>
                      )}
                      {view === "grid-12" && (
                        <div className="data-box-grid-12 mb-3">
                          <div className="item-header">
                            <p>
                              {item?.lot?.lot_number} | {item?.lot?.title}
                            </p>
                          </div>
                          <div className="detail-column">
                            <img
                              src={item?.lot?.images[0]?.image_path || JifImg}
                              alt="Lot "
                              loading="lazy"
                            />
                          </div>
                          <div className="detail-column">
                            <p className="pt-3 text-center">
                              {item?.status === "Winning"
                                ? "Winning"
                                : "Not Acceptable"}
                            </p>
                            <p className="ellipsis">{item?.lot?.description}</p>
                          </div>
                          <div className="detail-column">
                            <p className="text-center">
                              <b>{item?.amount}</b>
                            </p>
                          </div>
                          <div className="detail-column">
                            <p className="text-center">
                              {item?.lot?.shipping_available
                                ? "Shipping Available"
                                : "No Shipping"}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <div className="col-md-12">
                    <p>No data found</p>
                  </div>
                )}
              </div>
              <div className="pagination-controls">
                <button
                  className="pagination-button"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                {Array.from({ length: totalPages }, (_, i) => (
                  <button
                    key={i + 1}
                    className={`pagination-number ${
                      currentPage === i + 1 ? "active" : ""
                    }`}
                    onClick={() => handlePageChange(i + 1)}
                  >
                    {i + 1}
                  </button>
                ))}
                <button
                  className="pagination-button"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default MainData;
