import React, { useCallback, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import "./Adminsubscribe.css";
import { format } from "date-fns";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { CircularProgress } from "@mui/material";

function Adminsubscribe() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: 10,
  });

  const columns = [
    {
      name: "sr_no",
      label: "Sr.No",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return dataIndex + 1;
        },
        sort: false,
      },
    },
    { name: "email", label: "Email" },
    { name: "created_at", label: "Subscribed At", options: { sort: true } },
  ];

  const fetchData = useCallback(async (page = 1, perPage = 10) => {
    const accessToken = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/subscribers?page=${page}&per_page=${perPage}`,
        { headers }
      );
      // console.log(response.data.subscribers, "response");
      const { data, current_page, last_page, per_page } =
        response.data.subscribers;
      const formattedData = data.map((item) => ({
        ...item,
        created_at: format(new Date(item.created_at), "MMMM dd, yyyy, h:mm a"),
      }));
      setData(formattedData || []);
      // console.log("blog list", response.data.data)
      setLoading(false);
      setPagination({
        currentPage: current_page,
        lastPage: last_page,
        perPage: per_page,
      });
    } catch (error) {
      console.error("Error fetching auctions:", error);
    }
  });

  useEffect(() => {
    fetchData();
  }, []);

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    download: false,
    print: false,
    pagination: false,
    textLabels: {
      body: {
        noMatch: "No subscribers Found",
      },
    },
  };

 
  const handlePreviousPage = () => {
    if (pagination.currentPage > 1) {
      fetchData(pagination.currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (pagination.currentPage < pagination.lastPage) {
      fetchData(pagination.currentPage + 1);
    }
  };

  return (
    <section className="admin-subscribe">
      <div className="container">
        <div className="row mt-5 px-3">
          {/* <h2>ALL SUBSCRIBERS</h2> */}
          {loading ? (
            <div className="d-flex justify-content-center">
              <CircularProgress />
            </div>
          ) : (
            <>
              <MUIDataTable
                title={"Subscriber"}
                data={data || []}
                columns={columns}
                options={options}
              />
              <div className="d-flex justify-content-end align-items-center pagination">
                {pagination && (
                  <div className="d-flex align-items-center pagi">
                    <button
                      onClick={handlePreviousPage}
                      disabled={pagination.currentPage === 1}
                      cursor="pointer"
                    >
                      <ArrowBackIosIcon />
                    </button>
                    <span className="font-semibold ">
                      {pagination.currentPage} of {pagination.lastPage}
                    </span>
                    <button
                      onClick={handleNextPage}
                      disabled={pagination.currentPage === pagination.lastPage}
                      cursor="pointer"
                    >
                      <ArrowForwardIosIcon />
                    </button>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
}

export default Adminsubscribe;
