import React, { useEffect, useState } from "react";
import axios from "axios";
import { RingLoader } from "react-spinners";
import Woodburn8 from "../../../assets/images/demo.jpg";
import { Link } from "react-router-dom";

const Auctions = () => {
  const [auctions, setAuctions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [remainingTimes, setRemainingTimes] = useState({});

  const fetchAuctions = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/auctions/status/hot`, {
          headers: { "ngrok-skip-browser-warning": "69420" },
        })
        .then((response) => {
          const displayedAuctions = response.data.data?.slice(0, 8);
          setAuctions(displayedAuctions);
          // console.log(response.data.data, "hotttt");
        });
    } catch (error) {
      console.error("Error fetching auctions:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAuctions();
  }, []);

 

  useEffect(() => {
    // Update countdown every second
    const interval = setInterval(() => {
      const updatedTimes = {};
      auctions.forEach((auction) => {
        updatedTimes[auction.hash_id] = getRemainingTime(
          auction.end_date
        );
      });
      setRemainingTimes(updatedTimes);
    }, 1000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, [auctions]);

  const getRemainingTime = (endDate) => {
    const now = new Date();
    const end = new Date(endDate);
    const diff = end - now;

    if (diff <= 0) return "Closed";

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    if (days > 1) {
      return `${days} Days Left`;
    } else {
      const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((diff / (1000 * 60)) % 60);
      const seconds = Math.floor((diff / 1000) % 60);
      return `${hours}h ${minutes}m ${seconds}s left`;
    }
  };

  return (
    <div className="home" id="hot-auction">
      <div className="container">
        <div className="row">
          <div className="auctions mt-3 mb-3">
            <p className="title">Auctionslive</p>
            <p className="subtitle"> Live & Online Auctions.</p>
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col-md-6 col-sm-6">
            <h4 className="hot-auctions mt-2">Hot auctions</h4>
          </div>
        </div>
        <div className="row mt-3">
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "100vh" }}
            >
              <RingLoader color="#3498db" loading={loading} size={150} />
            </div>
          ) : auctions.length > 0 ? (
            auctions.map((auction, index) => (
              <div className="col-md-3 mb-4" key={index}>
                <Link
                  to={`/auctions/${auction.hash_id}`}
                  className="text-decoration-none"
                >
                  <div className="card woodburn-card">
                    <div className="card-body woodburn1">
                      <div className="woodburn1-img w-full h-[150px]">
                        <img
                          className="object-cover"
                          src={
                            auction?.auction_images &&
                            auction?.auction_images.length > 0
                              ? `${process.env.REACT_APP_BASE_IMAGE_URL}${auction?.auction_images[0]?.image_path}`
                              : Woodburn8
                          }
                          alt={auction?.name || "Auction Image"}
                          loading="lazy"
                        />
                      </div>
                      <p
                        className="card-text text-center mt-3 woodburn-text text-capitalize mb-0"
                        style={{
                          WebkitLineClamp: 2,
                          overflow: "hidden",
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          height: "40px",
                        }}
                      >
                        {auction?.name}
                      </p>
                      {/* <h6 className="text-center woodburn-text-heading">
                        Ends: {new Date(auction?.end_date).toLocaleDateString()}
                      </h6> */}

                      <p className="font-semibold mt-2 ">
                        {remainingTimes[auction.hash_id] || "Calculating..."}
                      </p>
                      <Link to={`/auctions/${auction.hash_id}`} className="text-decoration-none w-100">
                      <button className="btn btn-primary w-100">View Details</button>
                      </Link>
                    </div>
                  </div>
                </Link>
              </div>
            ))
          ) : (
            <p>No auctions available</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Auctions;
