import React, { useCallback, useEffect, useState } from "react";
import "./Bloglist.css";
import axios from "axios";
import MUIDataTable from "mui-datatables";
import { format } from "date-fns";
import AddIcon from "@mui/icons-material/Add";
import { CircularProgress } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import Addblog from "./Addblog";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Editblog from "./Editblog";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Button } from "react-bootstrap";

function Bloglist() {
  const [modalShow, setModalShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [editid, setEditid] = useState(null);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: 10,
  });

  const fetchBloglist = useCallback(async (page = 1, perPage = 10) => {
    const accessToken = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/posts/list?page=${page}&per_page=${perPage}`,
        { headers }
      );
      // console.log(response.data.data, "response");
      const { data, current_page, last_page, per_page } = response.data.data;
      const formattedData = data.map((item) => ({
        ...item,
        created_at: format(new Date(item.created_at), "MMMM dd, yyyy, h:mm a"),
      }));
      setData(formattedData || []);
      // console.log("blog list", response.data.data)
      setLoading(false);
      setPagination({
        currentPage: current_page,
        lastPage: last_page,
        perPage: per_page,
      });
    } catch (error) {
      console.error("Error fetching auctions:", error);
    }
  });

  useEffect(() => {
    fetchBloglist();
  }, []);

  const handleAddBlog = () => {
    setModalShow(true);
  };

  const columns = [
    {
      name: "sr_no",
      label: "Sr.No",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return dataIndex + 1; // Row index + 1
        },
        sort: false, // Disable sorting for serial number
      },
    },
    { name: "name", label: "Name" },
    {
      name: "description",
      label: "Description",
      options: {
        customBodyRender: (value) => {
          if (!value) {
            return "";
          }
          const words = value.split(" ");
          const truncatedDescription =
            words.length > 10 ? words.slice(0, 10).join(" ") + "..." : value;
          return (
            <div dangerouslySetInnerHTML={{ __html: truncatedDescription }} />
          );
        },
      },
    },
    {
      name: "created_at",
      label: "Created At",
      options: {
        sort: true,
      },
    },
    {
      name: "action",
      label: "Actions",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowId = data[tableMeta.rowIndex]?.id;
          return (
            <div>
              <button
                className="btn btn-sm"
                aria-label="Edit"
                onClick={() => handleEditBlog(rowId)}
              >
                <BorderColorIcon style={{ color: "green" }} />
              </button>
              <button
                className="btn btn-sm"
                aria-label="Delete"
                onClick={() => handleDeleteBlog(rowId)}
              >
                <DeleteIcon style={{ color: "red" }} />
              </button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    download: false,
    print: false,
    pagination: false,
    textLabels: {
      body: {
        noMatch: "No Blog Found",
      },
    },
  };

  const handleEditBlog = (id) => {
    setEditid(id);
    setEditShow(true);
    // console.log(`Edit blog with ID: ${id}`);
  };

  const handleDeleteBlog = async (id) => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      console.error("Access token not found in localStorage.");
      setLoading(false);
      return;
    }
    const apiHeaders = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "ngrok-skip-browser-warning": "69420",
      },
    };
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (!result.isConfirmed) {
      return;
    }
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/admin/posts/delete/${id}`,
          apiHeaders
        )
        .then((response) => {
          Swal.fire({
            title: "Deleted!",
            text: "Your blog post has been deleted.",
            icon: "success",
          });
          fetchBloglist();
        });
    } catch (error) {
      console.error("Error deleting blog:", error);
      toast.error("Error deleting blog");
    } finally {
      setLoading(false);
    }
  };

  const handlePreviousPage = () => {
    if (pagination.currentPage > 1) {
      fetchBloglist(pagination.currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (pagination.currentPage < pagination.lastPage) {
      fetchBloglist(pagination.currentPage + 1);
    }
  };

  return (
    <>
      <section className="admin-blog">
        <div className="container">
          <div className="row mt-5 px-3">
            {loading ? (
              <div className="d-flex justify-content-center">
                <CircularProgress />
              </div>
            ) : (
              <>
                <div className="blog-add d-flex justify-content-end align-items-center mb-3">
                  {/* <h2>Blog List</h2> */}
                  <Button variant="primary" onClick={handleAddBlog}>
                    <AddIcon /> Add Blog
                  </Button>
                </div>

                <MUIDataTable
                  title={"Blog List"}
                  data={data.length ? data : []}
                  columns={columns}
                  options={options}
                />
                <div className="d-flex justify-content-end align-items-center pagination">
                  {pagination && (
                    <div className="d-flex align-items-center pagi">
                      <button
                        onClick={handlePreviousPage}
                        disabled={pagination.currentPage === 1}
                        cursor="pointer"
                      >
                        <ArrowBackIosIcon />
                      </button>
                      <span className="font-semibold ">
                        {pagination.currentPage} of {pagination.lastPage}
                      </span>
                      <button
                        onClick={handleNextPage}
                        disabled={
                          pagination.currentPage === pagination.lastPage
                        }
                        cursor="pointer"
                      >
                        <ArrowForwardIosIcon />
                      </button>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </section>
      <Addblog
        open={modalShow}
        onClose={() => setModalShow(false)}
        fetchBloglist={fetchBloglist}
      />
      <Editblog
        open={editShow}
        onClose={() => setEditShow(false)}
        id={editid}
        fetchBloglist={fetchBloglist}
      />
    </>
  );
}

export default Bloglist;
