import React, { useState, useEffect, useCallback } from "react";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import HammerIcon from "@mui/icons-material/Gavel";
import { Box, CircularProgress } from "@mui/material";
import axios from "axios";
import { Link } from "react-router-dom";
import "./AddAuctionModal.css";
import MUIDataTable from "mui-datatables";
import { toast } from "react-toastify";
import SpeedIcon from "@mui/icons-material/Speed";
import NewAuction from "./NewAuction";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

let selectedRowIds = [];

function DashboardAuctioner() {
  const [rows, setRows] = useState([]);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [pagination, setPagination] = useState({ currentPage: 1, lastPage: 1 });
  const [loading, setLoading] = useState(true);
  const [isSelect, setIsSelect] = useState(false);

  const id = localStorage.getItem("hash_id");

  const opennewauction = () => {
    setOpen(true);
  };

  const fetchAuctions = useCallback(
    async (page = 1, perPage = 10) => {
      const accessToken = localStorage.getItem("access_token");
      setLoading(true);
      if (!accessToken) {
        console.error("Access token not found in localStorage.");
        return;
      }
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/list/auctions/${id}?page=${page}&per_page=${perPage}`,
          { headers }
        );

        const { data, current_page, last_page } = response.data.data;
        setRows(data);
        setPagination({ currentPage: current_page, lastPage: last_page });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching auctions:", error);
      }
    },
    [id]
  );

  useEffect(() => {
    fetchAuctions(pagination.currentPage);
  }, [fetchAuctions]);

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "start_date",
      label: "Start Date",
      options: {
        customBodyRender: (value) => (
          <span>{new Date(value).toLocaleDateString()}</span>
        ),
      },
    },
    {
      name: "end_date",
      label: "End Date",
      options: {
        customBodyRender: (value, tableMeta) => (
          <span>{new Date(value).toLocaleDateString()}</span>
        ),
      },
    },
    {
      name: "hash_id",
      label: " ",
      options: {
        customBodyRender: (value) => (
          <>
            <div className="d-flex flex-wrap align-items-center">
              <Link
                to={`/auctions/${value}`}
                style={{ textDecoration: "none" }}>
                <p
                  style={{
                    color: "#2695FF",
                    fontSize: "15px",
                    cursor: "pointer",
                    boxShadow: "#0000001a 0px 8px 24px",
                    fontWeight: "500",
                  }}
                  className="mb-0 p-2">
                  <SpeedIcon style={{ fontSize: "22px" }} /> Dashboard
                </p>
              </Link>
            </div>
          </>
        ),
      },
    },
  ];

  const options = {
    selectableRows: "none",
    print: false,
    download: false,
    filter: false,
    viewColumns: false,
    pagination: false,
    // isRowSelectable: (dataIndex) => true,
    // onRowSelectionChange: (
    //   currentRowsSelected,
    //   allRowsSelected,
    //   rowsSelected
    // ) => {
    //   const rowIds = allRowsSelected.map((row) => data[row.dataIndex].hash_id);
    //   // console.log("Selected Row IDs:", rowIds);
    //   // selected(rowIds);
    //   selectedRowIds = rowIds;
    //   setIsRowSelected(selectedRowIds.length > 0);
    
    // },
  };

  const data =
    rows &&
    rows.map((row) => ({
      name: row.name,
      start_date: row.start_date,
      end_date: row.end_date,
      state_name: row.state?.name,
      country_name: row.country?.name,
      status: row.status,
      hash_id: row.hash_id,
    }));

  const handleSend = () => {
    setIsSelect(true);
    // console.log(selectedRowIds, "auction id");
    axios
      .post(`${process.env.REACT_APP_API_URL}/bid-complete-mail`, {
        auction_id: selectedRowIds,
      })
      .then((result) => {
        // console.log(result)
        toast.success("Email sent successfully");
        setIsSelect(false);        
      })
      .catch((error) => {
        console.log(error);
        setIsSelect(false);
        toast.error("Something went wrong", error);
      });
  };

  const handlePreviousPage = () => {
    if (pagination.currentPage > 1) {
      fetchAuctions(pagination.currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (pagination.currentPage < pagination.lastPage) {
      fetchAuctions(pagination.currentPage + 1);
    }
  };

  return (
    <>
      <div className="container">
        <div className="row px-3">
          <Box
            mt={2}
            mb={2}
            p={2}
            boxShadow={3}
            bgcolor="white"
            style={{ background: "#fff" }}
          >
            <Grid
              container
              spacing={2}
              alignItems="center"
              className="flex items-center justify-between"
            >
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "20px",
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                <HammerIcon style={{ marginRight: "8px" }} />
                Auctions
              </Grid>
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "20px",
                }}
              >
                <IconButton
                  // onClick={handleOpenAdd}
                  onClick={opennewauction}
                  className="border-0 rounded-lg "
                >
                  <AddIcon style={{ marginRight: "8px" }} />
                  New Auction
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          {loading ? (
            <div className="d-flex justify-content-center">
              <CircularProgress />
            </div>
          ) : (
            <>
              <div>
                {isRowSelected && (
                  <button
                    className="btn btn-primary"
                    onClick={handleSend}
                    style={{ marginBottom: "16px" }}
                    disabled={isSelect}>
                    {!isSelect ? "Send Email" : "Sending..."}
                  </button>
                )}

                <MUIDataTable
                  data={data}
                  columns={columns}
                  options={options}                 
                />
              </div>
              <div className="d-flex justify-content-end align-items-center pagination"> 
                {pagination && (
                  <div className="d-flex align-items-center pagi">
                    <button
                      onClick={handlePreviousPage}
                      disabled={pagination.currentPage === 1}
                      cursor="pointer"
                    >
                      <ArrowBackIosIcon />
                    </button>
                    <span className="font-semibold ">
                      {pagination.currentPage} of {pagination.lastPage}
                    </span>
                    <button
                      onClick={handleNextPage}
                      disabled={pagination.currentPage === pagination.lastPage}
                      cursor="pointer"
                    >
                      <ArrowForwardIosIcon />
                    </button>
                  </div>
                )}
              </div>
            </>
          )}

          <NewAuction
            open={open}
            handleClose={handleClose}
            fetchAuctions={fetchAuctions}
          />
        </div>
      </div>
    </>
  );
}

export default DashboardAuctioner;
