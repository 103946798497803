import { React, useCallback, useEffect, useState } from "react";
import axios from "axios";
import MUIDataTable from "mui-datatables";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import EditCity from "./EditCity";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

function CityTabel() {
  const path = window.location.pathname;
  const hashId = path.split("/").pop();
  const [city, setCity] = useState([]);
  const [id, setId] = useState("");
  const [openedit, setOpenedit] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: 10,
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const fetchCity = useCallback(async (page = 1, perPage = 10) => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      // console.error("Access token not found in localStorage.");
      setLoading(false);
      return;
    }
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/cities/${hashId}?page=${page}&per_page=${perPage}`,
        { headers }
      );
      //   console.log(response.data, "response");
      const { current_page, last_page, per_page } = response.data.data;
      setCity(response.data.data.data);
      //   console.log(response.data.data.data, "data");
      setPagination({
        currentPage: current_page,
        lastPage: last_page,
        perPage: per_page,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching auctions:", error);
    }
  });

  useEffect(() => {
    fetchCity();
  }, []);

  const columns = [
    {
      name: "number",
      label: "SrNo.",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (_value, tableMeta) => {
          return (
            pagination.perPage * (pagination.currentPage - 1) +
            tableMeta.rowIndex +
            1
          );
        },
      },
    },
    {
      name: "name",
      label: "city",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "is_active",
      label: "Status",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const statusText = value === 0 ? "Inactive" : "Active";
          const statusColor = value === 0 ? "#6C757D" : "#2695FF";

          const rowData = tableMeta.rowData;

          return (
            <span
              style={{
                backgroundColor: statusColor,
                fontWeight: "500",
                fontSize: "14px",
                cursor: "pointer",
              }}
              className="badge rounded-pill p-2 ps-3 px-3"
              onClick={() => openEditModal(rowData)}
            >
              {statusText}
            </span>
          );
        },
      },
    },
    {
      name: "hash_id",
      label: "",
      options: {
        display: "false",
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
  ];

  const options = {
    filter: false,
    viewColumns: false,
    selectableRows: "none",
    responsive: "standard",
    download: false,
    print: false,
    pagination: false,
    textLabels: {
      body: {
        noMatch: "No city Found",
      },
    },
  };

  const handlePreviousPage = () => {
    if (pagination.currentPage > 1) {
      fetchCity(pagination.currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (pagination.currentPage < pagination.lastPage) {
      fetchCity(pagination.currentPage + 1);
    }
  };

  const openEditModal = (value) => {
    setId(value);
    // console.log(value)
    setOpenedit(true);
  };
  return (
    <>
      <section className="mt-5">
        <div className="container">
          {loading ? (
            <div className="d-flex justify-content-center">
              <CircularProgress />
            </div>
          ) : (
            <>
              <div className="row p-3">
                <button
                  className="btn btn-primary mb-3"
                  style={{ width: "max-content" }}
                  onClick={() => navigate(-1)}
                >
                  Go Back
                </button>
                <MUIDataTable
                  title={"City"}
                  data={city}
                  columns={columns}
                  options={options}
                />
              </div>
              <div className="d-flex justify-content-end align-items-center pagination">
                {pagination && (
                  <div className="d-flex align-items-center pagi">
                    <button
                      onClick={handlePreviousPage}
                      disabled={pagination.currentPage === 1}
                      cursor="pointer"
                    >
                      <ArrowBackIosIcon />
                    </button>
                    <span className="font-semibold ">
                      {pagination.currentPage} of {pagination.lastPage}
                    </span>
                    <button
                      onClick={handleNextPage}
                      disabled={pagination.currentPage === pagination.lastPage}
                      cursor="pointer"
                    >
                      <ArrowForwardIosIcon />
                    </button>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </section>
      <EditCity
        open={openedit}
        handleClose={() => setOpenedit(false)}
        value={id}
        fetchCity={fetchCity}
      />
    </>
  );
}

export default CityTabel;
