import React from "react";
import "./Sellerfees.css";

function Sellerfees() {
  return (
    <section className="sellerfees mt-5 mb-5">
      <div className="container">
        <div className="row">
          <h4>
            Whatever the primary category is for the auction, the entire auction
            is invoiced at that percentage:
          </h4>
          <p>
            Real Estate is <b>$500</b> per event
          </p>
          <h5>Commission Rates </h5>
          <p className="mb-0">
            Farm Equipment @ Machinery <b>2%</b>
          </p>
          <p className="mb-0">
            Heavy construction Equipment <b>2%</b>
          </p>
          <p className="mb-0">
            Commercial Trucks <b>2%</b>
          </p>
          <br />
          <br />
          <p className="mb-0">
            Cars and Vehicles <b>2%</b>
          </p>
          <p className="mb-0">
            Collectors cars <b>2%</b>
          </p>
          <p className="mb-0">
            Industrial Machinery @ Equipment <b>2%</b>
          </p>
          <p className="mb-0">
            Business liquidation <b>2%</b>
          </p>
          <br />
          <br />
          <p>
            Everything else <b>5% </b>
          </p>
          <p className="mb-0">
            Arts & Antiques <b>5% </b>
          </p>
          <p className="mb-0">
            Estate Property <b>5% </b>
          </p>
          <p className="mb-0">
            Firearms & Military artifacts <b>5% </b>
          </p>
          <p>
            Sports Memorabilia <b>5% </b>
          </p>
          <ol>
            <li>NO Auction fee accept Real Estate</li>
            <li>NO Monthly Fee</li>
            <li>NO unique bidder fee </li>
            <li>Unlimited company users</li>
            <li>Pass fees onto consumers .</li>
            <li>No contracts!</li>
            <li>Unlimited Auctions </li>
          </ol>
          <p>“ SELLERS CAN ADD RESERVES TO ANY ITEMS OF CHOICE”</p>
          <p>
            Try us out for 1 month free. Our new innovative site will get you
            the results you are seeking. “Sold “
          </p>
          <span>
          <a href="/" className="text-decoration-none">
            AUCTIONSLIVE.NET
          </a>
          </span>
        </div>
      </div>
    </section>
  );
}

export default Sellerfees;
