import React, { useCallback, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import EditUsers from "../EditUserDetail/EditUsers";
import { CircularProgress } from "@mui/material";

function UserList() {
  const [user, setUser] = useState([]);
  const [userdata, setUserdata] = useState("");
  const [openedit, setOpenedit] = useState(false);
  const handleCloseedit = () => setOpenedit(false);
  const [pagination, setPagination] = useState({ currentPage: 1, lastPage: 1 });
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(true);

  const fetchUser = useCallback(async (page = 1, perPage = 10) => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      // console.error("Access token not found in localStorage.");
      setLoading(false);
      return;
    }
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/users?page=${page}&per_page=${perPage}`,
        { headers }
      );
      // console.log(response.data, "response");
      const { data, current_page, last_page } = response.data;
      setUser(data);
      // console.log(data, "data");
      setPagination({
        currentPage: current_page.meta.current_page,
        lastPage: last_page.meta.last_page,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching auctions:", error);
    }
  });

  useEffect(() => {
    fetchUser();
  }, []);

  const columns = [
    {
      name: "number",
      label: "SrNo.",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (_value, tableMeta) => {
          return tableMeta.rowIndex + 1;
        },
      },
    },
    {
      name: "username",
      label: "Username",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "roles",
      label: "Roles",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          if (Array.isArray(value) && value.length > 0) {
            return value[0].name;
          }
          return " ";
        },
      },
    },
    {
      name: "is_active",
      label: "Status",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return value === 0 ? "Pending" : "Active";
        },
      },
    },

    {
      name: "hash_id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <>
              <div className="d-flex flex-wrap align-items-center gap-2">
                <EditIcon
                  fontSize="small"
                  sx={{ color: "green", cursor: "pointer" }}
                  onClick={() => openEditModal(value)}
                />
                <DeleteIcon
                  fontSize="small"
                  sx={{ color: "red", cursor: "pointer" }}
                  onClick={() => handleDeleteClick(value)}
                />
                <Link
                  to={`/admin/user-detail/${value}`}
                  style={{ textDecoration: "none" }}
                >
                  <p
                    style={{
                      color: "#2695FF",
                      fontSize: "15px",
                      cursor: "pointer",
                      fontWeight: "500",
                      marginBottom: "0",
                    }}
                  >
                    View
                  </p>
                </Link>
              </div>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    viewColumns: false,
    selectableRows: "none",
    responsive: "standard",
    download: false,
    print: false,
    pagination: false,
    textLabels: {
      body: {
        noMatch: "No User Found",
      },
    },
  };

  const openEditModal = (value) => {
    setUserdata(value);
    console.log(value);
    setOpenedit(true);
  };

  const handleDeleteClick = async (value) => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      console.error("Access token not found in localStorage.");
      return;
    }

    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "ngrok-skip-browser-warning": "69420",
    };

    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/users/delete/${value}?action=delete`,
          { headers }
        );
        Swal.fire("Deleted!", "The user has been deleted.", "success");
        fetchUser();
      } else {
        Swal.fire("Cancelled", "The user deletion was cancelled.", "info");
      }
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handlePreviousPage = () => {
    if (pagination.currentPage > 1) {
      fetchUser(pagination.currentPage - 1, perPage);
    }
  };

  const handleNextPage = () => {
    if (pagination.currentPage < pagination.lastPage) {
      fetchUser(pagination.currentPage + 1, perPage);
    }
  };

  return (
    <>
      <section className="mt-5">
        <div className="container">
          {/* <h2>Users List</h2> */}
          {loading ? (
            <div className="d-flex justify-content-center">
              <CircularProgress />
            </div>
          ) : (
            <>
              <div className="row mb-3">
                <div className="col-12">
                  <MUIDataTable
                    title={"Users"}
                    data={user}
                    columns={columns}
                    options={options}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end align-items-center pagination">
                {pagination && (
                  <div className="d-flex align-items-center pagi">
                    <button
                      onClick={handlePreviousPage}
                      disabled={pagination.currentPage === 1}
                      cursor="pointer"
                    >
                      <ArrowBackIosIcon />
                    </button>
                    <span className="font-semibold ">
                      {pagination.currentPage} of {pagination.lastPage}
                    </span>
                    <button
                      onClick={handleNextPage}
                      disabled={pagination.currentPage === pagination.lastPage}
                      cursor="pointer"
                    >
                      <ArrowForwardIosIcon />
                    </button>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </section>
      <EditUsers
        open={openedit}
        handleClose={handleCloseedit}
        userdata={userdata}
        fetchUser={fetchUser}
      />
    </>
  );
}

export default UserList;
