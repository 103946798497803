import { React, useCallback, useEffect, useState } from "react";
import axios from "axios";
import MUIDataTable from "mui-datatables";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import EditState from "./EditState";
import { CircularProgress } from "@mui/material";

function Statedetails() {
  const path = window.location.pathname; 
  const hashId = path.split("/").pop();
  const [state, setState] = useState([]);
  const [openedit, setOpenedit] = useState(false);
  const [id, setId] = useState("");
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: 10,
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);



  const fetchState = useCallback(async (page = 1, perPage = 10) => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      // console.error("Access token not found in localStorage.");
      setLoading(false);
      return;
    }
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/countries/state/${hashId}?page=${page}&per_page=${perPage}`,
        { headers }
      );
      // console.log(response.data, "response");
      const { current_page, last_page, per_page } = response.data.data;
      setState(response.data.data.data);
      // console.log(response.data.data.data, "data");
      setPagination({
        currentPage: current_page,
        lastPage: last_page,
        perPage: per_page,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching auctions:", error);
    }
  });

  useEffect(() => {
    fetchState();
  }, []);

  const columns = [
    {
      name: "number",
      label: "SrNo.",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (_value, tableMeta) => {
          return (
            pagination.perPage * (pagination.currentPage - 1) +
            tableMeta.rowIndex +
            1
          );
        },
      },
    },
    {
      name: "name",
      label: "State",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "is_active",
      label: "Status",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const statusText = value === 0 ? "Inactive" : "Active";
          const statusColor = value === 0 ? "#6C757D" : "#2695FF";

          const rowData = tableMeta.rowData;

          return (
            <span
              style={{
                backgroundColor: statusColor,
                fontWeight: "500",
                fontSize: "14px",
                cursor: "pointer",
              }}
              className="badge rounded-pill p-2 ps-3 px-3"
              onClick={() => openEditModal(rowData)}
            >
              {statusText}
            </span>
          );
        },
      },
    },
    {
      name: "hash_id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <>
              <div className="d-flex flex-wrap align-items-center gap-2">
                <Link
                  to={`/super/admin/city/${value}`}
                  style={{ textDecoration: "none" }}
                >
                  <p
                    style={{
                      color: "#2695FF",
                      fontSize: "14px",
                      cursor: "pointer",
                      fontWeight: "500",
                      marginBottom: "0",
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    }}
                    className="badge rounded-pill p-2 ps-3 px-3"
                  >
                    City
                  </p>
                </Link>
              </div>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    viewColumns: false,
    selectableRows: "none",
    responsive: "standard",
    download: false,
    print: false,
    pagination: false,
    textLabels: {
      body: {
        noMatch: "No State Found",
      },
    },
  };

  const handlePreviousPage = () => {
    if (pagination.currentPage > 1) {
      fetchState(pagination.currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (pagination.currentPage < pagination.lastPage) {
      fetchState(pagination.currentPage + 1);
    }
  };

  const openEditModal = (value) => {
    setId(value);
    // console.log(value, "value")
    setOpenedit(true);
  };

  return (
    <>
      <section className="mt-5">
        <div className="container">
          {loading ? (
              <div className="d-flex justify-content-center">
                <CircularProgress />
              </div>
            ) : (<>
          <div className="row p-3">
          <button
                className="btn btn-primary mb-3"
                style={{width: "max-content"}}
                onClick={() => navigate(-1)}
              >
                Go Back
              </button>
            <MUIDataTable
              title={"State"}
              data={state}
              columns={columns}
              options={options}
            />
          </div>
          <div className="d-flex justify-content-end align-items-center pagination">
            {pagination && (
              <div className="d-flex align-items-center pagi">
                <button
                  onClick={handlePreviousPage}
                  disabled={pagination.currentPage === 1}
                  cursor="pointer"
                >
                  <ArrowBackIosIcon />
                </button>
                <span className="font-semibold ">
                  {pagination.currentPage} of {pagination.lastPage}
                </span>
                <button
                  onClick={handleNextPage}
                  disabled={pagination.currentPage === pagination.lastPage}
                  cursor="pointer"
                >
                  <ArrowForwardIosIcon />
                </button>
              </div>
            )}
          </div>
            </>)}
        </div>
      </section>
      <EditState open={openedit} handleClose={() => setOpenedit(false)} value={id} fetchState={fetchState} />
    </>
  );
}

export default Statedetails;
