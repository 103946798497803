import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Button, Form, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MUIDataTable from "mui-datatables";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { CircularProgress } from "@mui/material";

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showEdit, setEditModal] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [newCategoryType, setNewCategoryType] = useState("main");
  const [imageFile, setImageFile] = useState(null);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [categoryToEdit, setCategoryToEdit] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: 10,
  });
  const [isSave, setSave] = useState(false);
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchCategories = useCallback(async (page = 1, perPage = 10) => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      // console.error("Access token not found in localStorage.");
      setLoading(false);
      return;
    }
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/categories/list?page=${page}&per_page=${perPage}`,
        { headers }
      );
      //   console.log(response.data, "response");
      const { data, current_page, last_page, per_page } = response.data.data;
      const mainCategories = data.filter(
        (category) => category.category_type === "main"
      );
      setCategories(mainCategories);
      // console.log(mainCategories, "header category")
      // console.log(data, "data");
      setPagination({
        currentPage: current_page,
        lastPage: last_page,
        perPage: per_page,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching auctions:", error);
    }
  });

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageFile(file);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const columns = [
    {
      name: "number",
      label: "SrNo.",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (_value, tableMeta) => {
          return (
            pagination.perPage * (pagination.currentPage - 1) +
            tableMeta.rowIndex +
            1
          );
        },
      },
    },
    {
      name: "name",
      label: "Category Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "category_type",
      label: "Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "is_active",
      label: "Status",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const statusText = value === 0 ? "Disable" : "Enable";
          const statusColor = value === 0 ? "#6C757D" : "#2695FF";
          return (
            <span
              style={{
                backgroundColor: statusColor,
                fontWeight: "500",
                fontSize: "14px",
                cursor: "pointer",
              }}
              className="badge rounded-pill p-2 ps-3 px-3"
            >
              {statusText}
            </span>
          );
        },
      },
    },
    {
      name: "hash_id",
      label: "ACTION",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <>
              <div className="d-flex flex-wrap align-items-center gap-2">
                <p
                  style={{ cursor: "pointer", color: "#2695FF" }}
                  onClick={() => handleViewCategory(value)}
                >
                  <RemoveRedEyeIcon />
                </p>
                <p
                  style={{ cursor: "pointer", color: "green" }}
                  onClick={() => handleShowEdit(value)}
                >
                  <EditIcon />
                </p>
                <p
                  style={{ cursor: "pointer", color: "red" }}
                  onClick={() => handleShowDeleteConfirm(value)}
                >
                  <DeleteIcon />
                </p>
              </div>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    viewColumns: false,
    selectableRows: "none",
    responsive: "standard",
    download: false,
    print: false,
    pagination: false,
    textLabels: {
      body: {
        noMatch: "No Category Found",
      },
    },
  };

  const handleCreateCategory = async () => {
    setSave(true);
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      console.error("Access token not found in localStorage.");
      return;
    }
    const apiHeaders = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "ngrok-skip-browser-warning": "69420",
        "Content-Type": "multipart/form-data",
      },
    };
    const formData = new FormData();
    formData.append("name", newCategoryName);
    formData.append("category_type", newCategoryType);
    formData.append("parent_id", "");
    if (imageFile) {
      formData.append("image", imageFile);
    }
    try {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/admin/categories/create`,
          formData,
          apiHeaders
        )
        .then((response) => {
          setSave(false);
          fetchCategories();
          setShowModal(false);
          setNewCategoryName("");
          setNewCategoryType("main");
          setImageFile(null);
          toast.success("Category Create successfully!");
        });
    } catch (error) {
      isSave(false);
      console.error("Error creating category:", error);
      toast.error("Error adding category. Please try again.");
    }
  };

  const handleViewCategory = (hash_id) => {
    navigate(`/super/admin/categories/${hash_id}`);
  };

  const handleShowDeleteConfirm = (hash_id) => {
    setCategoryToDelete(hash_id);
    setDeleteConfirmModal(true);
  };

  const handleDeleteCategory = async () => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      console.error("Access token not found in localStorage.");
      return;
    }
    const apiHeaders = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "ngrok-skip-browser-warning": "69420",
      },
    };
    try {
      await axios
        .delete(
          `${process.env.REACT_APP_API_URL}/admin/categories/delete/${categoryToDelete}`,
          apiHeaders
        )
        .then((response) => {
          toast.success("Category deleted successfully!");
          fetchCategories();
          setDeleteConfirmModal(false);
          setCategoryToDelete(null);
        });
    } catch (error) {
      console.error("Error deleting category:", error);
      toast.error("Error deleting category. Please try again.");
    }
  };

  const handleShowEdit = async (id) => {
    setCategoryToEdit(id);

    // console.log(id, "hash id");
    try {
      const accessToken = localStorage.getItem("access_token");
      if (!accessToken) {
        console.error("Access token not found in localStorage.");
        return;
      }
      const apiHeaders = {
        Authorization: `Bearer ${accessToken}`,
        "ngrok-skip-browser-warning": "69420",
      };
      await axios
        .get(`${process.env.REACT_APP_API_URL}/admin/categories/edit/${id}`, {
          headers: apiHeaders,
        })
        .then((response) => {
          const categoryData = response.data.data;

          // console.log(categoryData, "category data");
          formik.setFieldValue("name", categoryData.name);
          formik.setFieldValue("is_active", categoryData.is_active);
          setCurrentImage(
            `https://api.auctionslive.net/storage/${categoryData.media[0]?.file_path}`
          );
        });
    } catch (error) {
      console.error("Error fetching category data:", error);
    }
    setEditModal(true);
  };

  const handleImageChangeedit = (event) => {
    const file = event.target.files[0];
    if (file) {
      formik.setFieldValue("media", file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setCurrentImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      media: [],
      is_active: "",
      category_type: "main",
      parent_id: "",
    },
    onSubmit: async (values) => {
      setUpdate(true);
      // console.log(values, "form values");
      try {
        const accessToken = localStorage.getItem("access_token");
        if (!accessToken) {
          console.error("Access token not found in localStorage.");
          return;
        }

        const apiHeaders = {
          Authorization: `Bearer ${accessToken}`,
          "ngrok-skip-browser-warning": "69420",
        };

        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("is_active", parseFloat(values.is_active, 10));
        formData.append("category_type", values.category_type);
        formData.append("parent_id", values.parent_id);
        if (values.media) {
          formData.append("image", values.media);
        }

        await axios
          .post(
            `${process.env.REACT_APP_API_URL}/admin/categories/update/${categoryToEdit}`,
            formData,
            { headers: apiHeaders }
          )
          .then((response) => {
            toast.success("Category updated successfully!");
            setUpdate(false);
            // console.log("Category updated:", response);
            fetchCategories();
            setEditModal(false);
          });
      } catch (error) {
        setUpdate(false);
        console.error("Error updating category:", error);
      }
    },
  });

  const handlePreviousPage = () => {
    if (pagination.currentPage > 1) {
      fetchCategories(pagination.currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (pagination.currentPage < pagination.lastPage) {
      fetchCategories(pagination.currentPage + 1);
    }
  };

  return (
    <div className="container p-3">
      <div className="row pt-3">
        {loading ? (
          <div className="d-flex justify-content-center">
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="d-flex justify-content-end align-items-center mb-3">
              <Button variant="primary" onClick={() => setShowModal(true)}>
                Create Category
              </Button>
            </div>
            <div className="col-12">
              <MUIDataTable
                title={"Categories"}
                data={categories}
                columns={columns}
                options={options}
              />
              <div className="d-flex justify-content-end align-items-center pagination">
                {pagination && (
                  <div className="d-flex align-items-center pagi">
                    <button
                      onClick={handlePreviousPage}
                      disabled={pagination.currentPage === 1}
                      cursor="pointer"
                    >
                      <ArrowBackIosIcon />
                    </button>
                    <span className="font-semibold ">
                      {pagination.currentPage} of {pagination.lastPage}
                    </span>
                    <button
                      onClick={handleNextPage}
                      disabled={pagination.currentPage === pagination.lastPage}
                      cursor="pointer"
                    >
                      <ArrowForwardIosIcon />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form style={{ width: "100%" }}>
            <Form.Group controlId="formCategoryName">
              <Form.Label>Category Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter category name"
                value={newCategoryName}
                onChange={(e) => setNewCategoryName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formCategoryType">
              <Form.Label>Category Type</Form.Label>
              <Form.Control
                as="select"
                value={newCategoryType}
                onChange={(e) => setNewCategoryType(e.target.value)}
              >
                <option value="main">Main</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formImage">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <button
            className="btn btn-primary"
            type="submit"
            onClick={handleCreateCategory}
            disabled={isSave}
          >
            {isSave ? "Saving..." : "Save"}
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEdit} onHide={() => setEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
            <Form.Group controlId="formCategoryName">
              <Form.Label>Category Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Enter category name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </Form.Group>
            {currentImage && (
              <div className="mt-2">
                <img
                  src={currentImage}
                  alt="Current Category"
                  style={{
                    width: "100px",
                    maxHeight: "100px",
                    objectFit: "contain",
                  }}
                  loading="lazy"
                />
              </div>
            )}
            <Form.Group controlId="formImage">
              <Form.Label>Change Image</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={handleImageChangeedit}
              />
            </Form.Group>

            <Modal.Footer>
              <Button variant="secondary" onClick={() => setEditModal(false)}>
                Close
              </Button>
              <button
                className="btn btn-primary"
                type="submit"
                disabled={update}
              >
                {update ? "Updating..." : "Update"}
              </button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={deleteConfirmModal}
        onHide={() => setDeleteConfirmModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-black">
          Are you sure you want to delete this category?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setDeleteConfirmModal(false)}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteCategory}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </div>
  );
};
export default Categories;
