import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./Edituser.css";

function EditUsers({ handleClose, open, userdata, fetchUser }) {
  const [isSubmitting, setIsSubmitting] = useState("");
  const [user, setUserdata] = useState("");
  const [countryTypes, setCountryTypes] = useState([]);
  const [selectedCountryId, setSelectedCountryId] = useState();
  const [stateTypes, setStateTypes] = useState([]);
  const [selectedStateId, setSelectedStateId] = useState();
  const [cityTypes, setCityTypes] = useState([]);
  const [selectedCityId, setSelectedCityId] = useState();
  const [roles, setRoles] = useState([]);

  // console.log(lotdata, "lotdata")
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
  };

  /*---------------Country fetch-----------------*/
  useEffect(() => {
    if (user) {
      fetch(`${process.env.REACT_APP_API_URL}/countries`)
        .then((response) => response.json())
        .then((data) => {
          if (Array.isArray(data.data)) {
            setCountryTypes(data.data);
            const initialCountry = data.data.find(
              (type) => type.name === user?.country?.name
            );
            if (initialCountry) {
              setSelectedCountryId(initialCountry.hash_id);
            }
          } else {
            console.error("Expected an array but received:", data);
          }
        })
        .catch((error) => console.error("Error fetching data:", error));
    }
  }, [user]);

  const handleCountryChange = (event) => {
    console.log(event.target.value, "event country");
    const selectedId = event.target.value;
    setSelectedCountryId(selectedId);
    const selectedCountry = countryTypes.find(
      (type) => type.hash_id === selectedId
    );
    if (selectedCountry) {
    }
    formik.setFieldValue("country_id", selectedId);
  };

  /* ----------State fetch-----------*/
  const fetchStates = async (selectedCountryId) => {
    fetch(`${process.env.REACT_APP_API_URL}/states/${selectedCountryId}`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data.data)) {
          setStateTypes(data.data);
          const initialBiddingType = data.data.find(
            (type) => type.name === user?.state?.name
          );
          if (initialBiddingType) {
            setSelectedStateId(initialBiddingType.hash_id);
          }
        } else {
          console.error("Expected an array but received:", data);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const handleStateChange = (event) => {
    console.log(event.target.value, "event state");
    const selectedId = event.target.value;
    setSelectedStateId(selectedId);
    const selectedType = stateTypes.find((type) => type.hash_id === selectedId);
    if (selectedType) {
    }
    formik.setFieldValue("state_id", selectedId);
  };

  useEffect(() => {
    if (selectedCountryId) {
      fetchStates(selectedCountryId);
    }
  }, [selectedCountryId]);

  /*-----------------City--------------*/
  const fetchCity = async (selectedCityId) => {
    fetch(`${process.env.REACT_APP_API_URL}/city/${selectedCityId}`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data.data)) {
          setCityTypes(data.data);
          const initialBiddingType = data.data.find(
            (type) => type.name === user?.city?.name
          );
          if (initialBiddingType) {
            setSelectedCityId(initialBiddingType.hash_id);
          }
        } else {
          console.error("Expected an array but received:", data);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const handleCityChange = (event) => {
    console.log(event.target.value, "event city");
    const selectedId = event.target.value;
    setSelectedCityId(selectedId);
    const selectedType = cityTypes.find((type) => type.hash_id === selectedId);
    if (selectedType) {
    }
    formik.setFieldValue("city", selectedId);
  };

  useEffect(() => {
    if (selectedStateId) {
      fetchCity(selectedStateId);
    }
  }, [selectedStateId]);

  //---------------fetch role-------------
  const fetchRoles = async () => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      console.error("Access token not found in localStorage.");
      return;
    }
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "ngrok-skip-browser-warning": "69420",
    };
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/roles`, {
          headers,
        })
        .then((response) => {
          setRoles(response.data.roles || []);
        });
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  useEffect(() => {
    if (user) {
      fetchRoles();
    }
  }, [user]);

  //------------fetch user data--------------------
  const fetchlotdata = async () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/admin/users/${userdata}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        setUserdata(response.data);
        // console.log("edit user", response.data);
      })
      .catch((error) => {
        console.error("Error fetching lot data:", error);
      });
  };

  useEffect(() => {
    if (userdata) {
      fetchlotdata();
    }
  }, [userdata]);

  const validationSchema = Yup.object().shape({});

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: user?.username || "",
      email: user?.email || "",
      address: user?.detail?.address || "",
      city: user?.city?.hash_id || "",
      postal_code: user?.detail?.postal_code || "",
      phone1: user?.detail?.phone1 || "",
      phone2: user?.detail?.phone2 || "",
      country_id: user?.country?.hash_id || "",
      state_id: user?.state?.hash_id || "",
      hide_username: user?.settings?.hide_username || false,
      allow_magnifying_glass: user?.settings?.allow_magnifying_glass || false,
      send_email_notifications:
        user?.settings?.send_email_notifications || false,
      newsletter: user?.settings?.newsletter || false,
      role: user?.roles?.[0]?.id || "",
      is_active: user?.is_active || 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values, "form values");
      setIsSubmitting(true);
      try {
        const accessToken = localStorage.getItem("access_token");
        const apiHeaders = {
          Authorization: `Bearer ${accessToken}`,
        };
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/admin/users/update/${userdata}`,
            values,
            { headers: apiHeaders }
          )
          .then((response) => {
            // console.log(response, "response");
            setIsSubmitting(false);
            handleClose();
            fetchUser();
            toast.success("User Updated Successfully!");
          });
      } catch (error) {
        console.error("Error lot auction:", error);
        setIsSubmitting(false);
        toast.error("Error lot auction:", error);
      }
    },
  });

  //  useEffect(() => {
  //     if (formik.errors && Object.keys(formik.errors).length > 0) {
  //       console.log("Validation Errors:", formik.errors);
  //     }
  //   }, [formik.errors]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            className="d-grid p-4 userdetail-section"
            style={{ overflowY: "scroll", height: "500px" }}
          >
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <h2 style={{ color: "#2695FF" }}>User Edit</h2>
              <button
                type="button"
                class="close text-end"
                onClick={handleClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="d-flex align-items-center mb-3 gap-3">
                <div className="d-grid w-50">
                  <label>Username</label>
                  <div className="d-grid w-100">
                    <input
                      type="text"
                      placeholder="username"
                      name="username"
                      id="title"
                      value={formik.values.username}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.username && formik.errors.username && (
                      <p className="err mb-0">{formik.errors.username}</p>
                    )}
                  </div>
                </div>
                <div className="d-grid  w-50">
                  <label>Email</label>
                  <div className="d-grid  w-100">
                    <input
                      type="text"
                      placeholder="email"
                      name="email"
                      id="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <p className="err mb-0">{formik.errors.email}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center mb-3 gap-3">
                <div className="d-grid w-50">
                  <label>Address</label>
                  <div className="d-grid w-100">
                    <input
                      type="text"
                      placeholder="address"
                      name="address"
                      id="address"
                      value={formik.values.address}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.address && formik.errors.address && (
                      <p className="err mb-0">{formik.errors.address}</p>
                    )}
                  </div>
                </div>
                <div className="d-grid  w-50">
                  <label>Postal code</label>
                  <div className="d-grid  w-100">
                    <input
                      type="text"
                      placeholder="postal code"
                      name="postal_code"
                      id="postal_code"
                      value={formik.values.postal_code}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.postal_code &&
                      formik.errors.postal_code && (
                        <p className="err mb-0">{formik.errors.postal_code}</p>
                      )}
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center mb-3 gap-3">
                <div className="d-grid w-50">
                  <label>Country</label>
                  <div className="d-grid w-100">
                    <select
                      value={selectedCountryId}
                      onChange={handleCountryChange}
                      name="country"
                      id="country"
                      className="w-100 border-1 p-2"
                    >
                      {Array.isArray(countryTypes) &&
                        countryTypes.map((type) => (
                          <option key={type.hash_id} value={type.hash_id}>
                            {type.name}
                          </option>
                        ))}
                    </select>
                    {formik.touched.address && formik.errors.address && (
                      <p className="err mb-0">{formik.errors.address}</p>
                    )}
                  </div>
                </div>
                <div className="d-grid  w-50">
                  <label>State</label>
                  <div className="d-grid  w-100">
                    <select
                      value={selectedStateId}
                      onChange={handleStateChange}
                      name="state"
                      id="state"
                      className="w-100 border-1 p-2"
                    >
                      {Array.isArray(stateTypes) &&
                        stateTypes.map((type) => (
                          <option key={type.hash_id} value={type.hash_id}>
                            {type.name}
                          </option>
                        ))}
                    </select>
                    {formik.touched.postal_code &&
                      formik.errors.postal_code && (
                        <p className="err mb-0">{formik.errors.postal_code}</p>
                      )}
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center mb-3 gap-3">
                <div className="d-grid w-50">
                  <label>City</label>
                  <div className="d-grid w-100">
                    <select
                      value={selectedCityId}
                      onChange={handleCityChange}
                      name="city"
                      id="city"
                      className="w-100 border-1 p-2"
                    >
                      {Array.isArray(cityTypes) &&
                        cityTypes.map((type) => (
                          <option key={type.hash_id} value={type.hash_id}>
                            {type.name}
                          </option>
                        ))}
                    </select>
                    {formik.touched.address && formik.errors.address && (
                      <p className="err mb-0">{formik.errors.address}</p>
                    )}
                  </div>
                </div>
                <div className="d-grid  w-50">
                  <label>Phone1</label>
                  <div className="d-grid  w-100">
                    <input
                      type="text"
                      placeholder="phone1"
                      name="phone1"
                      id="phone1"
                      value={formik.values.phone1}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.phone1 && formik.errors.phone1 && (
                      <p className="err mb-0">{formik.errors.phone1}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center mb-3 gap-3">
                <div className="d-grid w-50">
                  <label>Phone2</label>
                  <div className="d-grid w-100">
                    <input
                      type="text"
                      placeholder="phone2"
                      name="phone2"
                      id="phone2"
                      value={formik.values.phone2}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.phone2 && formik.errors.phone2 && (
                      <p className="err mb-0">{formik.errors.phone2}</p>
                    )}
                  </div>
                </div>
                <div className="d-grid  w-50">
                  <label>Role</label>
                  <div className="d-grid  w-100">
                    <select
                      name="role"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.role}
                    >
                      <option value="">Select Role</option>
                      {roles.map((role) => (
                        <option key={role.id} value={role.id}>
                          {role.name}
                        </option>
                      ))}
                    </select>
                    {formik.touched.postal_code &&
                      formik.errors.postal_code && (
                        <p className="err mb-0">{formik.errors.postal_code}</p>
                      )}
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center mb-3 gap-3">
                <div className="d-grid w-50">
                  <div className="d-flex w-100 align-items-center gap-3">
                    <input
                      type="checkbox"
                      name="hide_username"
                      id="hide_username"
                      onChange={(e) => {
                        const value = e.target.checked ? 1 : 0;
                        formik.setFieldValue("hide_username", value);
                      }}
                      onBlur={formik.handleBlur}
                      checked={formik.values.hide_username === 1 || formik.values.hide_username === true}
                    />
                    <label htmlFor="hide_username">Hide username</label>
                    {formik.touched.hide_username &&
                      formik.errors.hide_username && (
                        <p className="err mb-0">
                          {formik.errors.hide_username}
                        </p>
                      )}
                  </div>
                </div>
                <div className="d-flex w-50 align-items-center gap-3">
                  <input
                    type="checkbox"
                    name="allow_magnifying_glass"
                    id="allow_magnifying_glass"
                    onChange={(e) => {
                      const value = e.target.checked ? 1 : 0;
                      formik.setFieldValue("allow_magnifying_glass", value);
                    }}
                    onBlur={formik.handleBlur}
                    checked={formik.values.allow_magnifying_glass === 1 || formik.values.allow_magnifying_glass === true}
                  />
                  <label htmlFor="allow_magnifying_glass">
                    Allow Magnifying Glass
                  </label>
                  {formik.touched.allow_magnifying_glass &&
                    formik.errors.allow_magnifying_glass && (
                      <p className="err mb-0">
                        {formik.errors.allow_magnifying_glass}
                      </p>
                    )}
                </div>
              </div>

              <div className="d-flex align-items-center mb-3 gap-3">
                <div className="d-grid w-50">
                  <div className="d-flex w-100 align-items-center gap-3">
                    <input
                      type="checkbox"
                      name="send_email_notifications"
                      id="send_email_notifications"
                      onChange={(e) => {
                        const value = e.target.checked ? 1 : 0;
                        formik.setFieldValue("send_email_notifications", value);
                      }}
                      onBlur={formik.handleBlur}
                      checked={formik.values.send_email_notifications === 1 || formik.values.send_email_notifications === true}
                    />
                    <label htmlFor="send_email_notifications">
                      Send Email Notifications
                    </label>
                    {formik.touched.send_email_notifications &&
                      formik.errors.send_email_notifications && (
                        <p className="err mb-0">
                          {formik.errors.send_email_notifications}
                        </p>
                      )}
                  </div>
                </div>
                <div className="d-flex w-50 align-items-center gap-3">
                  <input
                    type="checkbox"
                    name="newsletter"
                    id="newsletter"
                    onChange={(e) => {
                      const value = e.target.checked ? 1 : 0;
                      formik.setFieldValue("newsletter", value);
                    }}
                    onBlur={formik.handleBlur}
                    checked={formik.values.newsletter === 1 || formik.values.newsletter === true}
                  />
                  <label htmlFor="newsletter">Newsletter</label>
                  {formik.touched.newsletter && formik.errors.newsletter && (
                    <p className="err mb-0">{formik.errors.newsletter}</p>
                  )}
                </div>
              </div>

              <div className="d-flex align-items-center mb-3 gap-3">
                <div className="d-grid w-50">
                  <div className="d-flex w-100 align-items-center gap-3">
                    <input
                      type="checkbox"
                      name="is_active"
                      id="is_active"
                      onChange={(e) => {
                        const value = e.target.checked ? 1 : 0;
                        formik.setFieldValue("is_active", value);
                      }}
                      onBlur={formik.handleBlur}
                      checked={formik.values.is_active === 1}
                    />
                    <label htmlFor="is_active">Active</label>

                    {formik.touched.is_active && formik.errors.is_active && (
                      <p className="err mb-0">{formik.errors.is_active}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Updating..." : "Update"}
                </button>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default EditUsers;
