import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import "./Resetpassword.css";
import { toast } from "react-toastify";

function Resetpassword() {
  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tokenFromUrl = searchParams.get("token");
    const emailFromUrl = searchParams.get("email");

    if (tokenFromUrl && emailFromUrl) {
      setToken(tokenFromUrl);
      setEmail(emailFromUrl);
    }
  }, [location.search]);

  const validationSchema = Yup.object({
    password: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .required("Password is required"),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      password_confirmation: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsSubmit(true);
      const requestData = {
        token: token,
        email: email,
        password: values.password,
        password_confirmation: values.password_confirmation,
      };
      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/reset-password`,
          requestData
        );
        toast.success("Password reset successful!");
        setIsSubmit(false);
        setTimeout(() => {
          navigate("/");    
        }, 1000);
      } catch (error) {
        setIsSubmit(false);
        toast.error("Failed to reset password. Please try again.", error);
      }
    },
  });

  return (
    <>
      <section className="reset-password mt-5 mb-4">
        <div className="container">
          <Form onSubmit={formik.handleSubmit}>
            <h1 className="text-center mb-4">Reset Password</h1>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter new password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                autocomplete="current-password"
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.password && formik.errors.password}
              />
              {formik.touched.password && formik.errors.password ? (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.password}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="formBasicPasswordConfirmation">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm Password"
                name="password_confirmation"
                value={formik.values.password_confirmation}
                onChange={formik.handleChange}
                autocomplete="current-password"
                onBlur={formik.handleBlur}
                isInvalid={
                  formik.touched.password_confirmation &&
                  formik.errors.password_confirmation
                }
              />
              {formik.touched.password_confirmation &&
              formik.errors.password_confirmation ? (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.password_confirmation}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Button variant="primary" type="submit" disabled={isSubmit}>
              {isSubmit ? "Reset Password..." : "Reset Password"}
            </Button>
          </Form>
        </div>
      </section>
    </>
  );
}

export default Resetpassword;
