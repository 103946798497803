import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { CircularProgress, Grid, TextField } from "@mui/material";
import { toast } from "react-toastify";

function Editprofile() {
  const [userInfo, setUserInfo] = useState(null);
  const hash_id = localStorage.getItem("hash_id");
  const token = localStorage.getItem("access_token");
  const [roles, setRoles] = useState([]);

  const [countryTypes, setCountryTypes] = useState([]);
  const [selectedCountryId, setSelectedCountryId] = useState("");
  const [countrydata, setCountrydata] = useState(null);

  const [stateTypes, setStateTypes] = useState([]);
  const [statedata, setStatedata] = useState(null);
  const [selectedStateId, setSelectedStateId] = useState("");

  const [cityTypes, setCityTypes] = useState([]);
  const [citydata, setCitydata] = useState(null);
  const [selectedCityId, setSelectedCityId] = useState("");

  const [isSubmitting, setIssubmitting] = useState(false);
  const [loading, setLoading] = useState(true);


  // console.log(token)

  useEffect(() => {
    fetchUserInfo();
    fetchRoles();
  }, []);

  /*---------------Country fetch-----------------*/
  useEffect(() => {
    if (countrydata) {
      fetch(`${process.env.REACT_APP_API_URL}/countries`)
        .then((response) => response.json())
        .then((data) => {
          if (Array.isArray(data.data)) {
            setCountryTypes(data.data);
            const initialCountry = data.data.find(
              (type) => type.name === countrydata.name
            );
            if (initialCountry) {
              setSelectedCountryId(initialCountry.hash_id);
            }
          } else {
            console.error("Expected an array but received:", data);
          }
        })
        .catch((error) => console.error("Error fetching data:", error));
    }
  }, [countrydata]);

  const handleCountryChange = (event) => {
    // console.log(event.target.value, "event country")
    const selectedId = event.target.value;
    setSelectedCountryId(selectedId);
    const selectedCountry = countryTypes.find(
      (type) => type.hash_id === selectedId
    );
    if (selectedCountry) {
    }
    formik.setFieldValue("country", selectedId);
  };

  /* ----------State fetch-----------*/
  const fetchStates = async (selectedCountryId) => {
    fetch(`${process.env.REACT_APP_API_URL}/states/${selectedCountryId}`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data.data)) {
          setStateTypes(data.data);
          const initialBiddingType = data.data.find(
            (type) => type.name === statedata?.name
          );
          if (initialBiddingType) {
            setSelectedStateId(initialBiddingType.hash_id);
          }
        } else {
          console.error("Expected an array but received:", data);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const handleStateChange = (event) => {
    // console.log(event.target.value, "event state")
    const selectedId = event.target.value;
    setSelectedStateId(selectedId);
    const selectedType = stateTypes.find((type) => type.hash_id === selectedId);
    if (selectedType) {
    }
    formik.setFieldValue("state", selectedId);
  };

  useEffect(() => {
    if (selectedCountryId) {
      fetchStates(selectedCountryId);
    }
  }, [selectedCountryId]);

  /*-----------------City--------------*/
  const fetchCity = async (selectedCityId) => {
    fetch(`${process.env.REACT_APP_API_URL}/city/${selectedCityId}`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data.data)) {
          setCityTypes(data.data);
          const initialBiddingType = data.data.find(
            (type) => type.name === citydata?.name
          );
          if (initialBiddingType) {
            setSelectedCityId(initialBiddingType.hash_id);
          }
        } else {
          console.error("Expected an array but received:", data);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const handleCityChange = (event) => {
    // console.log(event.target.value, "event city")
    const selectedId = event.target.value;
    setSelectedCityId(selectedId);
    const selectedType = cityTypes.find((type) => type.hash_id === selectedId);
    if (selectedType) {
    }
    formik.setFieldValue("city", selectedId);
  };

  useEffect(() => {
    if (selectedStateId) {
      fetchCity(selectedStateId);
    }
  }, [selectedStateId]);

  /*------------get Roles-------------*/
  const fetchRoles = async () => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      console.error("Access token not found in localStorage.");
      return;
    }
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "ngrok-skip-browser-warning": "69420",
    };
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/roles`, {
          headers,
        })
        .then((response) => {
          setRoles(response.data.roles || []);
        });
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  /*------------get single user details-------------*/
  const fetchUserInfo = async () => {
    setLoading(true);
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/auctioneer/users/${hash_id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response.data;
          const formattedData = {
            ...data,
            address: data.detail?.address || "",
            company: data.detail?.company || "",
            // fax: data.detail?.fax || "",
            postal_code: data.detail?.postal_code || "",
            phone_1: data.detail?.phone1 || "",
            phone_2: data.detail?.phone2 || "",
            role: data.roles?.[0]?.id || "",
            hide_username: data.settings?.hide_username || false,
            allow_magnifying_glass:
              data.settings?.allow_magnifying_glass || false,
            send_email_notifications:
              data.settings?.send_email_notifications || false,
            newsletter: data.settings?.newsletter || false,
          };

          setUserInfo(formattedData);
          setCountrydata(data.country);
          setSelectedCountryId(data.country?.hash_id || "");
          setStatedata(data.state);
          setSelectedStateId(data.state?.hash_id || "");
          setCitydata(data.city);
          setSelectedCityId(data.city?.hash_id || "");
        });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching user info:", error);
    }
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    address: Yup.string().required("Address is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),   
  });

  /*------------single user detail update-------------*/
  const formik = useFormik({
    initialValues: {
      company: userInfo?.company || "",
      first_name: userInfo?.first_name || "",
      last_name: userInfo?.last_name || "",
      address: userInfo?.address || "",
      // fax: userInfo?.fax || "",
      country_id: selectedCountryId || "",
      state_id: selectedStateId || "",
      city: selectedCityId || "",
      postal_code: userInfo?.postal_code || "",
      phone1: userInfo?.phone_1 || "",
      phone2: userInfo?.phone_2 || "",
      username: userInfo?.username || "",
      email: userInfo?.email || "",
      hide_username: userInfo?.settings?.hide_username || 0,
      allow_magnifying_glass: userInfo?.settings?.allow_magnifying_glass || 0,
      send_email_notifications: userInfo?.settings?.send_email_notifications || 0,
      newsletter: userInfo?.settings?.newsletter || 0,
      role: userInfo?.roles?.[0]?.id || "",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      // console.log(values, "values")
      setIssubmitting(true);
      try {
        await axios
          .post(
            `${process.env.REACT_APP_API_URL}/auctioneer/users/update/${hash_id}`,
            values,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((response) => {
            setIssubmitting(false);
            fetchUserInfo();
            toast.success("Form Updated Successfully!");
          });
      } catch (error) {
        console.error("Error submitting form:", error);
        setIssubmitting(false);
        toast.error("Error", "Error submitting form");
      }
    },
  });

  return (
    <>
      <section className="edit-info mt-5 mb-5">
        <div className="container">
          <div className="row">
            {loading ? (
              <div className="d-flex justify-content-center">
                <CircularProgress />
              </div>
            ) : (<>

              <Form onSubmit={formik.handleSubmit} className="p-4">
                <h3 className="mb-3">Edit Personal Info</h3>
                <Form.Group className="mb-3 w-100 d-flex justify-content-between gap-2">
                  <Grid item className="w-50">
                    <TextField
                      type="text"
                      fullWidth
                      label="First Name"
                      name="first_name"
                      placeholder="first_name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.first_name}
                      isInvalid={
                        formik.touched.first_name && !!formik.errors.first_name
                      }
                    />
                  </Grid>
                  <Grid item className="w-50">
                    <TextField
                      fullWidth
                      label="Last Name"
                      type="text"
                      name="last_name"
                      placeholder="last_name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.last_name}
                      isInvalid={
                        formik.touched.last_name && !!formik.errors.last_name
                      }
                    />
                  </Grid>
                </Form.Group>

                <Form.Group className="mb-3 w-100 d-flex justify-content-between gap-2">
                  <Grid item className="w-100">
                    <TextField
                      fullWidth
                      label="Address"
                      type="text"
                      name="address"
                      placeholder="address"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.address}
                      isInvalid={
                        formik.touched.address && !!formik.errors.address
                      }
                    />
                  </Grid>
                 
                </Form.Group>

                <Form.Group className="mb-3 w-100 d-flex justify-content-between gap-2">
                  <select
                    value={selectedCountryId}
                    onChange={handleCountryChange}
                    name="country"
                    id="country"
                    className="w-100 border-1 p-2"
                  >
                    {Array.isArray(countryTypes) &&
                      countryTypes.map((type) => (
                        <option key={type.hash_id} value={type.hash_id}>
                          {type.name}
                        </option>
                      ))}
                  </select>
                  <select
                    value={selectedStateId}
                    onChange={handleStateChange}
                    name="state"
                    id="state"
                    className="w-100 border-1 p-2"
                  >
                    {Array.isArray(stateTypes) &&
                      stateTypes.map((type) => (
                        <option key={type.hash_id} value={type.hash_id}>
                          {type.name}
                        </option>
                      ))}
                  </select>
                </Form.Group>

                <Form.Group className="mb-3 w-100 d-flex justify-content-between gap-2">
                  <Grid item className="w-50">
                    <select
                      value={selectedCityId}
                      onChange={handleCityChange}
                      name="state"
                      id="state"
                      className="w-100 border-1 p-2"
                    >
                      {Array.isArray(cityTypes) &&
                        cityTypes.map((type) => (
                          <option key={type.hash_id} value={type.hash_id}>
                            {type.name}
                          </option>
                        ))}
                    </select>
                  </Grid>
                  <Grid item className="w-50">
                    <TextField
                      fullWidth
                      label="Postal Code"
                      type="text"
                      name="postal_code"
                      placeholder="postal_code"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.postal_code}
                      isInvalid={
                        formik.touched.postal_code && !!formik.errors.postal_code
                      }
                    />
                  </Grid>
                </Form.Group>
                <Form.Group className="mb-3 w-100 d-flex justify-content-between gap-2">
                  <Grid item className="w-50">
                    <TextField
                      fullWidth
                      label="Phone 1"
                      type="text"
                      name="phone1"
                      placeholder="phone1"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phone1}
                      isInvalid={
                        formik.touched.phone1 && !!formik.errors.phone1
                      }
                    />
                  </Grid>
                  <Grid item className="w-50">
                    <TextField
                      fullWidth
                      label="Phone 2"
                      type="text"
                      name="phone2"
                      placeholder="phone2"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phone2}
                      isInvalid={
                        formik.touched.phone2 && !!formik.errors.phone2
                      }
                    />
                  </Grid>
                </Form.Group>

                <Form.Group className="mb-3 w-100 d-flex justify-content-between gap-2">
                  <Grid item className="w-50">
                    <TextField
                      fullWidth
                      label="UserName"
                      type="text"
                      name="username"
                      placeholder="username"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.username}
                      isInvalid={
                        formik.touched.username && !!formik.errors.username
                      }
                    />
                  </Grid>
                  <Grid item className="w-50">
                    <TextField
                      fullWidth
                      label="Email"
                      type="text"
                      name="email"
                      placeholder="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      isInvalid={formik.touched.email && !!formik.errors.email}
                    />
                  </Grid>
                </Form.Group>
                <Form.Group className="mb-3 w-100 d-flex justify-content-between gap-2">
                  <Grid item className="w-50">
                    <TextField
                      fullWidth
                      label="Company Name"
                      type="text"
                      name="company"
                      placeholder="Company Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.company}
                      isInvalid={
                        formik.touched.company && !!formik.errors.company
                      }
                    />
                  </Grid>
                  <select
                    name="role"
                    className="w-50 border-1 p-2"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.role}
                  >
                    <option value="">Select Role</option>
                    {roles.map((role) => (
                      <option key={role.id} value={role.id}>
                        {role.name}
                      </option>
                    ))}
                  </select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formHideUsername">
                  <Form.Check
                    type="checkbox"
                    label="Hide Username"
                    name="hide_username"
                    onChange={(e) => {
                      const value = e.target.checked ? 1 : 0;
                      formik.setFieldValue("hide_username", value);
                    }}
                    checked={formik.values.hide_username === 1 || formik.values.hide_username === true}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formAllowMagnifyingGlass">
                  <Form.Check
                    type="checkbox"
                    label="Allow Magnifying Glass"
                    name="allow_magnifying_glass"
                    onChange={(e) => {
                      const value = e.target.checked ? 1 : 0;
                      formik.setFieldValue("allow_magnifying_glass", value);
                    }}
                    checked={formik.values.allow_magnifying_glass === 1 || formik.values.allow_magnifying_glass === true}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="formSendEmailNotifications"
                >
                  <Form.Check
                    type="checkbox"
                    label="Send Email Notifications"
                    name="send_email_notifications"
                    onChange={(e) => {
                      const value = e.target.checked ? 1 : 0;
                      formik.setFieldValue("send_email_notifications", value);
                    }}
                    checked={formik.values.send_email_notifications === 1 || formik.values.send_email_notifications === true}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formnewsletter">
                  <Form.Check
                    type="checkbox"
                    label="newsletter"
                    name="newsletter"
                    onChange={(e) => {
                      const value = e.target.checked ? 1 : 0;
                      formik.setFieldValue("newsletter", value);
                    }}
                    checked={formik.values.newsletter === 1 || formik.values.newsletter === true}
                  />
                </Form.Group>
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting ? "Submitting..." : "Submit"}
                </Button>
              </Form>
            </>)}
          </div>
        </div>
      </section>
    </>
  );
}

export default Editprofile;
