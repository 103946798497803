import React, { useEffect, useState } from "react";
import axios from "axios";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import demo from "../../../assets/images/demo.jpg";

function Banner() {
  const [auctions, setAuctions] = useState([]);

  const fetchAuctions = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/latest/auctions`, {
          headers: { "ngrok-skip-browser-warning": "69420" },
        })
        .then((response) => {
          // console.log(response.data.data);
          setAuctions(response.data.data);
        });
    } catch (error) {
      console.error("Error fetching auctions:", error);
    }
  };

  useEffect(() => {
    fetchAuctions();
  }, []);

  const settings = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className="banner-slide">
        <div className="container">
          <div className="row">
            <Slider {...settings}>
              {auctions &&
                auctions.map((auction, index) => (
                  <div key={index} className="slider-card mt-2 mb-3">
                    <div className="slider-image">
                      <Link
                        to={`/auctions/${auction.hash_id}`}
                        className="text-decoration-none"
                      >
                        <img
                          src={
                            auction?.auction_images?.length > 0
                              ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${auction?.auction_images[0]?.image_path}`
                              : demo
                          }
                          alt={auction.text}
                          className="slider-image"
                          loading="lazy"
                        />
                      </Link>
                    </div>
                    <div className="slider-text text-center">
                      <Link
                        to={`/auctions/${auction.hash_id}`}
                        className="text-decoration-none text-black"
                      >
                        <h4
                          className="slider-text text-capitalize mt-2"
                          style={{
                            WebkitLineClamp: 1,
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                          }}
                        >
                          {auction?.name}
                        </h4>
                      </Link>
                      {/* <p className="slider-desc">{auction?.description}</p> */}
                      <div className="d-flex justify-content-evenly align-items-center">
                        <p className="slider-heading mb-0 font-semibold">
                          <span className="font-bold">Lots:</span>{" "}
                          {auction?.lots?.length}
                        </p>
                        <p className="slider-heading mb-0 font-semibold">
                          <span className="font-bold">End:</span>{" "}
                          {new Date(auction?.end_date).toLocaleDateString()}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
}

export default Banner;
