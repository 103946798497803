import React, { useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";

function EditState({ handleClose, open, value, fetchState }) {
  const [isSubmitting, setIsSubmitting] = useState("");

  const stateId = value[3]; // Assuming this is the country ID
  const initialStatus = value[2]; // Assuming this is the active status

  // console.log(value, "data");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      is_active: initialStatus || 0,
    },

    onSubmit: (values) => {
      // console.log(values, "form values");
      setIsSubmitting(true);
      try {
        const accessToken = localStorage.getItem("access_token");
        const headers = {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        };
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/admin/states/disable/${stateId}?status=${values.is_active}`,
            { headers }
          )
          .then((response) => {
            // console.log(response, "response");
            setIsSubmitting(false);
            handleClose();
            fetchState();
            toast.success("State Updated Successfully!");
          });
      } catch (error) {
        console.error("Error lot auction:", error);
        setIsSubmitting(false);
        toast.error("Error lot auction:", error);
      }
    },
  });

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            className="d-grid p-3 pt-0 pb-0 userdetail-section"
            style={{ height: "300px" }}
          >
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <h2 style={{ color: "#2695FF" }}>Confirm Action</h2>
              <button
                type="button"
                class="close text-end"
                onClick={handleClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="d-flex align-items-center mb-3 gap-3">
                <div className="d-grid w-50">
                  <div className="d-flex w-100 gap-4">
                    <input
                      type="checkbox"
                      name="is_active"
                      id="is_active"
                      onChange={(e) =>
                        formik.setFieldValue(
                          "is_active",
                          e.target.checked ? 1 : 0
                        )
                      }
                      checked={formik.values.is_active === 1}
                    />
                    <label htmlFor="is_active">Active</label>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end gap-3">
                <button className="btn btn-danger" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Updating..." : "Update"}
                </button>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default EditState;
