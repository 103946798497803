import axios from "axios";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./Bidstatus.css"
import { CircularProgress } from "@mui/material";

function Bidstatus() {
  const { id } = useParams();
  const [bids, setBid] = useState([]);
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({ currentPage: 1, lastPage: 1 });
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(true);


  const fetchbids = useCallback(async (page = 1, perPage = 10) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/lot/bidder/${id}?page=${page}&per_page=${perPage}`
      );
      // console.log(response.data, "response")
      const { current_page, last_page } = response.data.data;
      setBid(response.data.data.data);
      // console.log("bids", response.data.data.data)
      setPagination({ currentPage: current_page, lastPage: last_page });
      setLoading(false);
    } catch (error) {
      console.error("Error fetching bids:", error);
      setLoading(false);
    }
  });

  useEffect(() => {
    fetchbids();
  }, []);

  const columns = [
    {
      name: "number",
      label: "No.",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (_value, tableMeta) => {
          return tableMeta.rowIndex + 1;
        },
      },
    },
    {
      name: "bidder",
      label: "Username",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            bids[tableMeta.rowIndex]?.bidder?.first_name +
            " " +
            bids[tableMeta.rowIndex]?.bidder?.last_name || "N/A"
          );
        },
      },
    },
    {
      name: "bidder.email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return bids[tableMeta.rowIndex]?.bidder?.email || "N/A";
        },
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "created_at",
      label: "Date",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return (
            <span>
              {new Date(value).toLocaleDateString()}
            </span>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return (
            <span
              style={{
                color: value === "Winning" ? "green" : "red",
              }}
            >
              {value}
            </span>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    viewColumns: false,
    selectableRows: "none",
    responsive: "standard",
    download: false,
    print: false,
    pagination: false,
    textLabels: {
      body: {
        noMatch: "No Bid yet",
      },
    },
  };

  const handlePreviousPage = () => {
    if (pagination.currentPage > 1) {
      fetchbids(pagination.currentPage - 1, perPage);
    }
  };

  const handleNextPage = () => {
    if (pagination.currentPage < pagination.lastPage) {
      fetchbids(pagination.currentPage + 1, perPage);
    }
  };

  return (
    <>
      <section className="mt-5 mb-3">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <button
                className="btn btn-primary mb-3"
                onClick={() => navigate(-1)}>
                Go Back
              </button>
              {loading ? (
                <div className="d-flex justify-content-center">
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <MUIDataTable
                    title={"Bid Status"}
                    data={bids}
                    columns={columns}
                    options={options}
                  />
                  <div className="d-flex justify-content-end align-items-center pagination">
                    {pagination && (
                      <div className="d-flex align-items-center pagi">
                        <button
                          onClick={handlePreviousPage}
                          disabled={pagination.currentPage === 1}
                          cursor="pointer">
                          <ArrowBackIosIcon />
                        </button>
                        <span className="font-semibold ">
                          {pagination.currentPage} of {pagination.lastPage}
                        </span>
                        <button
                          onClick={handleNextPage}
                          disabled={pagination.currentPage === pagination.lastPage}
                          cursor="pointer">
                          <ArrowForwardIosIcon />
                        </button>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Bidstatus;
