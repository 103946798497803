import React, { useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";

function EditCountry({ handleClose, open, value, fetchCountry }) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const countryId = value[3]; // Assuming this is the country ID
  const initialStatus = value[2]; // Assuming this is the active status

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    p: 3,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      is_active: initialStatus || 0,
    },
    onSubmit: async (values) => {
      setIsSubmitting(true);
      try {
        const accessToken = localStorage.getItem("access_token");
        const headers = {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        };
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/countries/disable/${countryId}?status=${values.is_active}`,
          { headers }
        );
        // console.log(response, "API Response");
        handleClose();
        fetchCountry();
        toast.success("Country Updated Successfully!");
      } catch (error) {
        console.error("Error updating country:", error);
        toast.error("Failed to update country");
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <div className="p-3">
          <div className="d-flex justify-content-between align-items-center">
            <h2 style={{ color: "#2695FF" }}>Confirm Action</h2>
            <button type="button" className="close" onClick={handleClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="d-flex align-items-center mb-3 gap-3">
              <div className="d-flex w-100 gap-2">
                <input
                  type="checkbox"
                  id="is_active"
                  onChange={(e) =>
                    formik.setFieldValue("is_active", e.target.checked ? 1 : 0)
                  }
                  checked={formik.values.is_active === 1}
                />
                <label htmlFor="is_active">Active</label>
              </div>
            </div>
            <div className="d-flex justify-content-end gap-3">
              <button className="btn btn-danger" type="button" onClick={handleClose}>
                Cancel
              </button>
              <button className="btn btn-primary" type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Updating..." : "Update"}
              </button>
            </div>
          </form>
        </div>
      </Box>
    </Modal>
  );
}

export default EditCountry;
