import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { CardActions, CardContent, CardMedia, Typography } from "@mui/material";
import { Button, Card } from "react-bootstrap";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { RingLoader } from "react-spinners";
import demo from "../../assets/images/demo.jpg";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function Search() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const name = queryParams.get("name");
  const zipCode = queryParams.get("zip_code");
  const miles = queryParams.get("miles");

  const [loading, setLoading] = useState(false); // Loading state
  const [suggestions, setSuggestions] = useState([]);
  const [lotsuggestions, setLotSuggestions] = useState([]);

  const [pagination, setPagination] = useState({ currentPage: 1, lastPage: 1 });
  const [perPage, setPerPage] = useState(40);

  const handleSearchSubmit = useCallback(
    async (page = 1, perPage = 40) => {
      setLoading(true);
      try {
        await axios
          .get(
            `${process.env.REACT_APP_API_URL}/search?page=${page}&per_page=${perPage}`,
            {
              params: { name, zip_code: zipCode, miles: miles },
              headers: { "ngrok-skip-browser-warning": "69420" },
            }
          )
          .then((response) => {
            if (response.data.type === "lots") {
              const { current_page, last_page } = response.data.data;
              setPagination({ currentPage: current_page, lastPage: last_page });
              setSuggestions([]);
              setLotSuggestions(response.data.data.data);
            } else {
              const { current_page, last_page } = response.data.data;
              setPagination({ currentPage: current_page, lastPage: last_page });
              setLotSuggestions([]);
              setSuggestions(response.data.data.data);
            }
          });
      } catch (error) {
        console.error("Error searching events:", error);
        // toast.error("No data found");
      } finally {
        setLoading(false);
      }
    },
    [name, zipCode, miles]
  );

  useEffect(() => {
    handleSearchSubmit();
    setLotSuggestions([]);
    setSuggestions([]);
  }, [name, zipCode, miles, location]);

  const handlePreviousPage = () => {
    if (pagination.currentPage > 1) {
      handleSearchSubmit(pagination.currentPage - 1, perPage);
    }
  };

  const handleNextPage = () => {
    if (pagination.currentPage < pagination.lastPage) {
      handleSearchSubmit(pagination.currentPage + 1, perPage);
    }
  };

  return (
    <div className="container">
      {loading ? (
        <div className="d-flex justify-content-center w-100">
          <RingLoader color={"#123abc"} loading={loading} size={150} />
        </div>
      ) : suggestions.length === 0 && lotsuggestions.length === 0 ? (
        <p className="font-semibold text-center mt-4">No data found</p>
      ) : (
        <>
          <div className="d-flex flex-wrap align-items-center  w-100 search-result gap-3 mt-4 mb-4">
            {suggestions &&
              suggestions.map((item, index) => (
                <Card key={index}>
                  <CardMedia
                    component="img"
                    image={
                      item?.auction_images?.length > 0
                        ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${item?.auction_images[0]?.image_path}`
                        : demo
                    }
                    alt={item.name}
                    loading="lazy"
                  />
                  <CardContent className="text-center">
                    <Typography
                      gutterBottom
                      style={{
                        color: "#666",
                        fontSize: 16,
                        textTransform: "capitalize",
                        fontWeight: "bold",
                        WebkitLineClamp: 1,
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {item.name}
                    </Typography>
                    <Typography
                      gutterBottom
                      style={{
                        color: "#666",
                        fontSize: 16,
                        textTransform: "capitalize",
                      }}
                    >
                      High Bid: {item.highest_bidder?.amount || 0}
                    </Typography>
                    <p className="mb-0 font-bold" style={{ color: "#2695FF" }}>
                      {item?.end_date
                        ? new Date(item?.auction?.end_date) > new Date()
                          ? Math.ceil(
                              (new Date(item?.end_date) - new Date()) /
                                (1000 * 60 * 60 * 24)
                            ) > 1
                            ? `${Math.ceil(
                                (new Date(item?.end_date) - new Date()) /
                                  (1000 * 60 * 60 * 24)
                              )} days left`
                            : "Last day"
                          : "Closed"
                        : "No end date"}
                    </p>
                    {(item.highest_bidder?.amount || 0) <
                      item.reserve_price && (
                      <p className="reserve-text text-danger font-semibold mb-0">
                        <ErrorOutlineIcon /> Reserve Not Met
                      </p>
                    )}
                  </CardContent>
                  <CardActions className="justify-content-center">
                    <Link
                      to={`/auctions/${item.hash_id}`}
                      className="text-decoration-none text-dark d-flex"
                    >
                      <Button variant="primary" size="small">
                        View Details
                      </Button>
                    </Link>
                  </CardActions>
                </Card>
              ))}

            {lotsuggestions.length > 0 &&
              lotsuggestions.map((item, index) => (
                <Card key={index}>
                  <CardMedia
                    component="img"
                    image={
                      item?.auction_images?.length > 0
                        ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${item?.auction_images[0]?.image_path}`
                        : demo
                    }
                    alt={item.name}
                    loading="lazy"
                  />
                  <CardContent className="text-center">
                    <Typography
                      gutterBottom
                      style={{
                        color: "#666",
                        fontSize: 16,
                        textTransform: "capitalize",
                        fontWeight: "bold",
                        WebkitLineClamp: 1,
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      gutterBottom
                      style={{
                        color: "#666",
                        fontSize: 16,
                        textTransform: "capitalize",
                      }}
                    >
                      High Bid: {item?.highest_bidder?.amount || 0}
                    </Typography>
                    <p className="mb-0 font-bold" style={{ color: "#2695FF" }}>
                      {item?.auction?.end_date
                        ? new Date(item?.auction?.end_date) > new Date()
                          ? Math.ceil(
                              (new Date(item?.auction?.end_date) - new Date()) /
                                (1000 * 60 * 60 * 24)
                            ) > 1
                            ? `${Math.ceil(
                                (new Date(item?.auction?.end_date) -
                                  new Date()) /
                                  (1000 * 60 * 60 * 24)
                              )} days left`
                            : "Last day"
                          : "Closed"
                        : "No end date"}
                    </p>

                    {(item.highest_bidder?.amount || 0) <
                      item.reserve_price && (
                      <p className="reserve-text text-danger font-semibold mb-0">
                        <ErrorOutlineIcon /> Reserve Not Met
                      </p>
                    )}
                  </CardContent>
                  <CardActions className="justify-content-center">
                    <Link
                      to={`/lots/${item.hash_id}`}
                      className="text-decoration-none text-dark d-flex"
                    >
                      <Button variant="primary" size="small">
                        View Details
                      </Button>
                    </Link>
                  </CardActions>
                </Card>
              ))}
          </div>
          <div className="d-flex justify-content-end align-items-center pagination">
            {pagination && (
              <div className="d-flex align-items-center pagi">
                <button
                  onClick={handlePreviousPage}
                  disabled={pagination.currentPage === 1}
                  cursor="pointer"
                >
                  <ArrowBackIosIcon />
                </button>
                <span className="font-semibold">
                  {pagination.currentPage} of {pagination.lastPage}
                </span>
                <button
                  onClick={handleNextPage}
                  disabled={pagination.currentPage === pagination.lastPage}
                  cursor="pointer"
                >
                  <ArrowForwardIosIcon />
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default Search;
